import React, { useMemo, useState, useEffect } from 'react';
import '../css/makeTable.css'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from '../store/utils';
import Loader from '../component/Loader';
import Moment from 'react-moment';
import Modal from 'react-modal';
import { FaRegTimesCircle } from 'react-icons/fa';
import { formatDateTimeSecond } from '../component/common';
import { AiOutlineFilePdf } from "react-icons/ai";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: "none"
  },
};

function LeadDistributorDetails(props) {


  const urlSearchParams = new URLSearchParams(window.location.search);
  const leadId = urlSearchParams.get("leadId");

  const [merchantPersonalData, setMerchantPersonalData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalImageURl, setModalImageURl] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [viewPdf, setviewPdf] = useState('')
  async function distributorGetData() {
    if (leadId) {
      setIsLoadingOn(true);
      const filterData = {
        leadId : leadId
      }
      const tempData = await postData(`lead/getLeads`, filterData)
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        const personalInfo = tempData?.data?.records[0] || [];
        setMerchantPersonalData(personalInfo);
        setviewPdf(personalInfo?.ledgerFile)
      }
    }
  }

  useEffect(() => {
    distributorGetData();
  }, [leadId])

  const [activeTab, setActiveTab] = useState("home");

  const handleViewPdf = () => {
    window.open(viewPdf, "_blank"); // Open the PDF in a new tab
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Sr. No.',
        accessor: "leadId",
        Cell: ({ cell }) => {
          return (
            <>
              <span style={{ fontWeight: "600" }}>{cell.row.index + 1}</span>
            </>
          );
        },
      },
      {
        Header: 'Interested',
        accessor: 'isInterested'
      },
      {
        Header: 'Reason',
        accessor: 'reason'
      },
      {
        Header: 'Uploaded Date',
        accessor: 'updatedAt',
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original.createdAt}
            </>
          );
        },
      },
    ],
    []
  );

  const handleOpenViewImage = (filePath)=>{
    setModalImageURl(filePath);
    setIsOpen(true);
    }

  return (
    <div>
      <div className="container-fluid">
        <div className="row alignmentTable topOrderHeaderBorder mb-3">
          <div className="col-md-10">
            <h5 style={{ marginBottom: 0 }}>Lead Distributor Details</h5>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs creditReportTab" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "home" ? "active" : ""}`}
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected={activeTab === "home"}
            onClick={() => handleTabClick("home")}
          >
            <strong>Personal Info</strong>
          </button>
        </li>
      </ul>
      {isLoadingOn ? <Loader /> : ""}
      <div className="tab-content" id="myTabContent">
        {activeTab === "home" && (
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <section className="orderDetailsSection">
              <div className="container-fluid">
                <div className="row" style={{ marginLeft: "-25px", marginTop: "25px" }}>
                  <div className="col-md-12">
                    <div className="basicDetailsUser">
                      <table className="userDetailsTableInfo">
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan={2}>User Info </th>

                        </tr>
                        <tr>
                          <th>Business Name :</th>
                          <td>{merchantPersonalData ? merchantPersonalData?.businessName : ""}</td>
                        </tr>
                        <tr>
                          <th>Owner Name :</th>
                          <td>{merchantPersonalData ? merchantPersonalData?.ownerName : ""}</td>
                        </tr>
                        <tr>
                          <th>Mobile Number:</th>
                          <td>{merchantPersonalData && merchantPersonalData?.merchantMobile ? "XXXXXX" + merchantPersonalData.merchantMobile?.slice(-4) : "-"}</td>
                        </tr>
                        <tr>
                          <th>Pan Number:</th>
                          <td>
                            {merchantPersonalData && merchantPersonalData?.panNumber == null ? "N/A" : merchantPersonalData?.panNumber}
                          </td>
                        </tr>
                        <tr>
                          <th>Branch:</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.branch : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>Address :</th>
                          <td>{merchantPersonalData ? merchantPersonalData?.address : ""}</td>
                        </tr>
                        <tr>
                          <th>Pin Code :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.pinCode : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>City :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.city : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>State :</th>
                          <td>
                            {merchantPersonalData ? merchantPersonalData?.state : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>Created At:</th>
                          <td>
                            {merchantPersonalData ? formatDateTimeSecond(merchantPersonalData?.createdAt) : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Ledger File:</th>
                          <td>
                            <AiOutlineFilePdf style={{fontSize: "40px", cursor: "pointer"}} onClick={handleViewPdf} />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                </div>
                {/* -------------------- form row ---------------------- */}
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  )
}




export default LeadDistributorDetails;
