import React, { useEffect, useRef, useState } from "react";
import { useTable, usePagination } from "react-table";
import { useQuery, useQueryClient } from "react-query";
import { merchantGetData } from "./fetchData";
import Loader from "./Loader";

const trimData = (data = []) =>
  data.map(
    ({
      merchantReferenceNumber,
      name,
      shopName,
      fosName,
      channel,
      pricingGridId,
      mobileNumber,
      createdAt,
      statusName,
      userShopId,
      userId,
      orderPermit,
      financierId,
      nameNBFC,
      sanctionPdfPath,
      sanctionKfsPdfPath,
      PanCard,
      AadhaarCard,
      TradeCertificate,
      UdyamAadhar,
      onboardingStatusName
    }) => ({
      merchantReferenceNumber,
      name,
      shopName,
      fosName,
      channel,
      pricingGridId,
      mobileNumber,
      createdAt,
      statusName,
      userShopId,
      userId,
      orderPermit,
      financierId,
      nameNBFC,
      sanctionPdfPath,
      sanctionKfsPdfPath,
      PanCard,
      AadhaarCard,
      TradeCertificate,
      UdyamAadhar,
      onboardingStatusName
    })
  );

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 5,
  totalCount: null,
};

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

function TablePagination({ searchManualData, columns, pageType, resetFiter }) {
  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] =
    React.useReducer(reducer, initialState);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [isFilterDataInfo, setIsFilterDataInfo] = useState(searchManualData);
  const queryClient = useQueryClient();

  const showLoading = (loadingType = false) => {
    setIsLoadingOn(loadingType);
  };

  const fetchData = async (
    pageIndex,
    pageSize,
    showLoading,
    searchManualData
  ) => {
    if (pageType === "merchantData") {
      return await merchantGetData(
        pageIndex,
        pageSize,
        showLoading,
        searchManualData
      );
    }
    //  else if (pageType === 'orderList') {
    //     return await orderListGetData(pageIndex, pageSize, showLoading, searchManualData);
    // }
  };

  const { data, isSuccess, refetch } = useQuery(
    [pageType, queryPageIndex, isFilterDataInfo, queryPageSize, resetFiter],
    async () => {
      if (isFilterDataInfo && isFilterDataInfo.length > 0) {
        return await fetchData(
          queryPageIndex,
          queryPageSize,
          showLoading,
          isFilterDataInfo || {}
        );
      } else {
        return await fetchData(
          queryPageIndex,
          queryPageSize,
          showLoading,
          isFilterDataInfo || {}
        );
      }
    },
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (resetFiter) {
      queryClient.removeQueries([
        "merchants",
        queryPageIndex,
        queryPageSize,
        isFilterDataInfo,
      ]);
      setIsFilterDataInfo();
      refetch({ isFilterDataInfo: {} });
    }
  }, [resetFiter, refetch]);

  useEffect(() => {
    if (searchManualData) {
      setIsFilterDataInfo(searchManualData);
    }
  }, [searchManualData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: isSuccess ? trimData(data.data) : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
    },
    usePagination
  );

  React.useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  React.useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  React.useEffect(() => {
    if (data?.allRecordsTotal) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.allRecordsTotal,
      });
    }
  }, [data?.allRecordsTotal]);

  return (
    <>
      {isLoadingOn ? <Loader /> : null}
      {isSuccess && data ? (
        <>
          <table {...getTableProps()} className="dashboardTable">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row m-0">
            <div className="pagination col-lg-12">
              <div className="col-lg-2">
                <button
                  className="defaultPaginationButton"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>{" "}
                <button
                  className="defaultPaginationButton"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>{" "}
                <button
                  className="defaultPaginationButton"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {">"}
                </button>{" "}
                <button
                  className="defaultPaginationButton"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>{" "}
              </div>
              <div className="col-lg-2">
                <span>
                  Total Records <strong>{data.allRecordsTotal}</strong>{" "}
                </span>
              </div>
              <div className="col-lg-2">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </div>
              <div className="col-lg-3">
                <span>
                  Go to page{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    className="gotoPage"
                  />
                </span>{" "}
              </div>
              <div className="col-lg-3">
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="selectDisplayNoOfRecords"
                >
                  {[5, 10, 20, 30, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />
        </>
      ) : null}
    </>
  );
}

export default TablePagination;
