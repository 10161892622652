import React, { useEffect, useState, useRef, useMemo } from 'react';
import Table from "./ReactTable";
import {
    useAsyncDebounce,
} from "react-table";
import { FaSearch, } from 'react-icons/fa';
import { HiRefresh } from "react-icons/hi";
import '../css/makeTable.css';
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import { getData, postData } from '../store/utils';
import Loader from '../component/Loader';
import Modal from 'react-modal';
import { FaRegTimesCircle } from 'react-icons/fa';
import TopShowMessage from '../component/TopShowMessage';
import Swal from "sweetalert2";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: "none",
        padding: "0px",
    },
};



function DocumentsDetails() {

    const [merchantUserData, setMerchantUserData] = useState();
    const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
    const [merchantRefrenceType, setMerchantRefrenceType] = useState();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [merchantLoanData, setMerchantLoanData] = useState();
    const [searchBoxValue, setSearchBoxValue] = useState();
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [showTopMessage, setShowTopMessage] = useState(false)
    const [loanStatusData, setLoanStatusData] = useState();
    const [applyFor, setApplyFor] = useState('loan');
    const [modalImageURl, setModalImageURl] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
    const [pageSize, setPageSize] = useState(10); // Default page size
    const [errorMessage, setErrorMessage] = useState("");
    const [sortBy, setSortBy] = useState(""); 
    const [orderBy, setOrderBy] = useState("");

    const inputRef = useRef();


    const resetFilters = () => {
        inputRef.current.value = '';
        setMerchantRefrenceType('');
        setSearchBoxValue('');
        updateOrderStatus();
        setSelectedOptionStatus(null);
    };


    const data = useMemo(
        () => [
            { id: 1, name: 'John Doe', age: 25, city: 'New York', mobileNo: '9988776786' },
        ],
        []
    );

    const searchManualData = async () => {
        let filterData = {};

        if (merchantRefrenceType === "User Id") {
            filterData = {
                userId: searchBoxValue,
            };
        } else if (merchantRefrenceType === "Shop Name") {
            filterData = {
                shopName: searchBoxValue,
            };
        }
        else if (merchantRefrenceType === "Mobile Number") {
            filterData = {
                mobileNumber: searchBoxValue,
            };
        }
        else {
            alert("Please select any one option");
        }
        setIsLoadingOn(true);
        const queryParams = new URLSearchParams(filterData).toString();
        const tempData = await getData(`documents/documentVerifiedData/nothing?${queryParams}`);
        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            setMerchantUserData(tempData.data.records);
        }
        else{
            Swal.fire({
                title:"Error!",
                text:tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    };


    const history = useHistory();

    const updateOrderStatus = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
        setIsLoadingOn(true);

        let apiUrl = `documents/documentVerifiedData/nothing?page=${page + 1}&pageSize=${pageSize}`;
    
        if (sortBy && orderBy) {
            apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
        }
        const tempData = await getData(apiUrl)
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setMerchantUserData(tempData.data.records);
            setPageCount(tempData.data.pagination.totalPagesCount);
            setTotalRecords(tempData.data.pagination.totalRecords);
            setErrorMessage("");
        }
        else{
            Swal.fire({
                title:"Error!",
                text:tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    }

    useEffect(() => {
        updateOrderStatus(pageIndex, pageSize, sortBy, orderBy);
    }, [pageIndex, pageSize, sortBy, orderBy])

    const handleGotoPage = (page) => {
        if (page < 0 || page >= pageCount) {
          setErrorMessage(`No data to display`);
      } else {
          setPageIndex(page);
      }
    };

    const handleSort = (column) => {
        let newOrderBy = "asc";
      
      if (sortBy === column.id) {
        newOrderBy = orderBy === "asc" ? "desc" : "asc";
      }
      setSortBy(column.id);
      setOrderBy(newOrderBy);
      updateOrderStatus(pageIndex, pageSize, column.id, newOrderBy);
      };

    const statusModalOpen = (item) => {
        setLoanStatusData(item);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    }

    const imageViewModal = (filePath) => {
        setModalImageURl(filePath);
        setIsOpen(true);

    }

    const columns = useMemo(
        () => [
            {
                Header: "Sr. No.",
                accessor: (row, i) => i + 1 + pageIndex * pageSize,
                Cell: ({ cell }) => (
                  <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
                ),
              },
            {
                Header: 'User Id',
                accessor: 'userId',

            },
            {
                Header: 'Shop Name',
                accessor: 'shopName'
            },
            {
                Header: 'Mobile No.',
                accessor: 'mobileNumber'
            },
             {
                Header: 'User Type',
                accessor: 'userType'
            },
            {
                Header: 'Document Name',
                accessor: "documentName",
            },
            {
                Header: 'Image',
                accessor: "filePath",
                Cell: ({ cell }) => {
                    const fileType = cell.row.original.fileType;
                    return (
                        <>
                            {fileType == "image" ?
                                (<div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => imageViewModal(cell.row.original)}>
                                    <img style={{ maxHeight: 50, }} src={cell.row.original.filePath} />
                                </div>
                                ) :
                                (
                                    <a href={cell.row.original.filePath} download>
                                        <img style={{ maxHeight: 50, }} src="../assets/img/pdf.png" />
                                    </a>
                                )

                            }
                        </>
                    );
                },
            },
        ],
        [pageIndex,pageSize]
    );

    const merchantOption = [
        { value: 'User Id', label: 'User Id' },
        { value: 'Shop Id', label: 'Shop Id' },
        { value: 'Mobile Number', label: 'Mobile Number' },
    ]

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}
            <h3 className='tableHeaderMsg'>Documents List</h3>
            <div className='customHeaderTable'>
                <div className="container-fluid">
                    <div className="row alignmentTable">
                        <div className="col-md-2">
                            <Select
                                placeholder="Select Search"
                                name="role"
                                options={merchantOption}
                                value={selectedOptionStatus}
                                onChange={(e) => {
                                    setMerchantRefrenceType(e.value);
                                    setSelectedOptionStatus(e)
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <input
                                type="number"
                                className='searchInputField'
                                ref={inputRef}
                                onChange={(e) => {
                                    setSearchBoxValue(e.target.value);
                                }}
                                placeholder='Enter Details'
                            />
                        </div>
                        <div className="col-md-2 text-center">
                            <button className='searchTableData'
                                onClick={searchManualData}
                            ><FaSearch /> Search</button>
                        </div>

                        <div className="col-md-2 text-center" onClick={resetFilters}>
                            <button className='resetTableData' ><HiRefresh /> Reset</button>
                        </div>
                        {/* <div className="col-lg-4 text-end">
                            <button className='btn btnAddCredit'
                                onClick={() => {
                                    history.push('/ProgramAddData')
                                }}
                            >Add</button>
                        </div> */}
                    </div>
                </div>


            </div>
            <div className='orderListDataFooter'>
                {merchantUserData && <Table 
                columns={columns} 
                data={merchantUserData} 
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                handleGotoPage={handleGotoPage}
                errorMessage={errorMessage} 
                handleSort={handleSort}
                sortBy={sortBy}
                orderBy={orderBy}
                />}
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button className='modalCloseBtn' onClick={closeModal}><FaRegTimesCircle /></button>
                {modalImageURl && modalImageURl.fileType == "image" &&
                    <div className='modalUserImage'>
                        <img src={modalImageURl && modalImageURl.filePath} alt='modalImage' />
                    </div>
                }
            </Modal>

            {/* ------------- status View Modal ---------------------- */}

        </div>
    );
}

export default DocumentsDetails;

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{" "}
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <div>
            <input
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <input
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "70px",
                    marginRight: "0.5rem",
                }}
            />
            to
            <input
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "70px",
                    marginLeft: "0.5rem",
                }}
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
