import React, { useEffect, useState } from "react";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import Select from "react-select";
import TopShowMessage from "../component/TopShowMessage";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const ProductAddData = () => {
  const history = useHistory();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [totalAddBrandRows, setTotalAddBrandRows] = useState([{ id: 1 }]);
  const [totalAddDistributorRows, setTotalAddDistributorRows] = useState([{ id: 1 }]);
  const [userBrands, setUserBrands] = useState([]);
  const [distributorUser, setDistributorUser] = useState([]);
  const [distributorProductType, setDistributorProductType] = useState([]);
  const [distributorSubProductType, setDistributorSubProductType] = useState([]);
  const [distributorUserData, setDistributorUserData] = useState([]);
  const [programData, setProgramData] = useState([]);

  const [formData, setFormData] = useState({
    productName: "",
    productCategory: "",
    subCategory: '',
    brand: "",
    size: "",
    color: "",
    mostPurchasedProduct: "1",
    filePath1: "",
    filePath2: "",
    filePath3: "",
    description: "",
    userId: "1",
    isActive: 1,
    distrubutorProductObject: [{ distributorId: '', programId: '', mrp: '', listingPrice: '', isDistActive: 1}],
    deleteDistributorProductObj: [{ id: '', distributorId: '', productId: '' }],
  });
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const numberFields = [
    "discount",
  ];

  const statusOptions=[
    { label: "Published", value: 1 },
    { label: "Not Published", value: 0 },
  ]
  
  const statusDistOptions=[
    { label: "Published", value: 1 },
    { label: "Not Published", value: 0 },
  ]

  const parseToNumber = (formData) => {
    const parsedData = { ...formData };
    numberFields.forEach((field) => {
      if (parsedData[field] !== "") {
        parsedData[field] = parseFloat(parsedData[field]);
      }
    });
    return parsedData;
  };

const sendData = async () => {
    const parsedFormData = parseToNumber(formData);
    setIsLoadingOn(true);
    const res = await postData(`electrician/addProductCatalog`, parsedFormData);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      history.push("/ProductList");
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data updated successfully!",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

    const handleUpdateChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };

  const loadFormDataForEditing = async (id) => {
    try {
      setIsLoadingOn(true);
      const programDataResponse = await getData(
        `electrician/getProductCatalogList?id=${id}`
      );
      if (programDataResponse.statusCode == 200) {
      const data = programDataResponse.data.records[0];
      setFormData(data);
      } else {
        console.error("Error loading data:", programDataResponse.message);
      }
      setIsLoadingOn(false);
    } catch (error) {
      console.error("Error loading program data:", error);
      setIsLoadingOn(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendData();
  };
  
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      loadFormDataForEditing(id);
    }
  }, [id]);

  async function getBrands() {
    setIsLoadingOn(true);
    const tempData = await getData("users/getBrands/1");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200 && tempData.data.records) {
        const tempDistData = tempData.data.records.map(element => ({
            value: element.id,
            label: element.name,
        }));
        setUserBrands(tempDistData);
    } else {
      console.error("Error fetching User brands.");
      Swal.fire({
          title:"Error!",
          text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  }

  async function getProductType() {
    setIsLoadingOn(true);
    const tempData = await getData("users/getProductCategory/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200 && tempData.data.records) {
        const tempDistData = tempData.data.records.map(element => ({
            value: element.id,
            label: element.name,
        }));
        setDistributorProductType(tempDistData);
    } else {
        console.error("Error fetching distributor Product Type");
        Swal.fire({
            title:"Error!",
            text:tempData?.data?.errors.msg || "Something went wrong!!!"
          })
    }
  }

  async function getSubProductType(productCategoryId) {
    setIsLoadingOn(true);
    let tempData = await getData(`users/getProductSubCategory/1?productCategoryId=${productCategoryId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode === 200 && tempData.data.records) {
        const tempDistData = tempData.data.records.map(element => ({
            value: element.id,
            label: element.name,
        }));
        setDistributorSubProductType(tempDistData);
    } else {
        console.error("Error fetching distributor Sub Product Type");
        Swal.fire({
            title: "Error!",
            text: tempData?.data?.errors.msg || "Something went wrong!!!"
        });
    }
}

  useEffect(() => {
      getBrands();
      getProductType();
  }, [])

  const handleAddProductChange = (selectedOption) => {
    if (selectedOption) {
        const selectedProductCategoryId = selectedOption.value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            productCategory: selectedOption.label,
            subCategory: "", 
        }));

        setDistributorSubProductType([]);
        getSubProductType(selectedProductCategoryId);
    } else {
        setDistributorSubProductType([]);
        setFormData((prevFormData) => ({
            ...prevFormData,
            productCategory: "",
            subCategory: "",
        }));
    }
  };

  const handleAddSubProductChange = (selectedOption) => {
    if (selectedOption) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            subCategory: selectedOption.label,
        }));
    }
  };

  const handleAddBrandChange = (selectedOption) => {
    if (selectedOption) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            brand: selectedOption.label,
        }));
    }
  };

  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            isActive: selectedOption.value,
        }));
    }
  };
  
  async function DistributorGetData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/distributorUser/1", {})
    setIsLoadingOn(false);
    const tempDistData = [];
    if (tempData.statusCode == 200) {
        for (let i = 0; i < tempData.data.records.length; i++) {
            const element = tempData.data.records[i];
            tempDistData.push({ value: element.id, label: element.name+"-"+element.id })
        }
        // console.log("tempDistData----",tempDistData)
        setDistributorUserData(tempDistData);
    }
    else{
        Swal.fire({
            title:"Error!",
            text:tempData?.data?.errors.msg || "Something went wrong!!!"
          })
    }
  }

  useEffect(() => {
      DistributorGetData();
      getProgramData();
  }, [])

  async function getProgramData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/getMasterProgram/1");
    setIsLoadingOn(false);
    const tempDistData = [];
    if (tempData.statusCode == 200) {
      const tempDistData = tempData.data.map(element => ({
        value: element.id,
        label: element.programName
      }));
      setProgramData(tempDistData);
    }
    else{
      Swal.fire({
          title:"Error!",
          text:tempData?.data?.errors.msg || "Something went wrong!!!"
        })
    }
  }

  const handleFileInputChange = (event, key) => {
    const file = event.target.files[0];

    if (file) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(file.type)) {
            Swal.fire({
                icon: "error",
                title: "Invalid File!",
                text: "Please upload a valid image file (JPG, JPEG, PNG).",
            });
            event.target.value = "";
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const base64URL = reader.result;
            const pdfUrl = base64URL.split(',')[1];
            setFormData(prevState => ({
                ...prevState,
                [key]: pdfUrl
            }));
        };
        reader.readAsDataURL(file);
    }
  };

  const handleAddDistributorChange = (rowId, selectedOption) => {
    if (totalAddBrandRows.some(row => row.id === rowId)) {
        setTotalAddBrandRows(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, selectedOption } : row
            )
        );
    } else {
        const newRow = { id: totalAddBrandRows.length + 1, selectedOption };
        setTotalAddBrandRows(prevRows => [...prevRows, newRow]);
    }
    setFormData(prevState => ({
        ...prevState,
        distrubutorProductObject: prevState.distrubutorProductObject.map((row, index) =>
            index + 1 === rowId ? { ...row, distributorId: selectedOption.value } : row
        )
    }));
  };

  const handleAddProgramChange = (rowId, selectedOption) => {
    if (totalAddBrandRows.some(row => row.id === rowId)) {
        setTotalAddBrandRows(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, selectedOption } : row
            )
        );
    } else {
        const newRow = { id: totalAddBrandRows.length + 1, selectedOption };
        setTotalAddBrandRows(prevRows => [...prevRows, newRow]);
    }
    setFormData(prevState => ({
        ...prevState,
        distrubutorProductObject: prevState.distrubutorProductObject.map((row, index) =>
            index + 1 === rowId ? { ...row, 
              programId: selectedOption.value,
            } : row
        )
    }));
  };

  const handleDistributorProductStatusChange = (rowId, selectedOption) => {
    if (totalAddBrandRows.some(row => row.id === rowId)) {
        setTotalAddBrandRows(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, selectedOption } : row
            )
        );
    } else {
        const newRow = { id: totalAddBrandRows.length + 1, selectedOption };
        setTotalAddBrandRows(prevRows => [...prevRows, newRow]);
    }
    setFormData(prevState => ({
        ...prevState,
        distrubutorProductObject: prevState.distrubutorProductObject.map((row, index) =>
          index + 1 === rowId
            ? {
                ...row,
                isDistActive: selectedOption.value,
              }
            : row
        )
    }));
  };

  const handleUpdateRowChange = (event, rowIndex, arrayName) => {
    const { name, value } = event.target;
    const numericValue = value.match(/^-?\d*\.?\d*$/);
    setFormData(prevState => {
        const updatedArray = [...prevState[arrayName]];
        updatedArray[rowIndex] = {
            ...updatedArray[rowIndex],
            [name]: value
        };
        return {
            ...prevState,
            [arrayName]: updatedArray
        };
    });
  };

  const addBrandAddRow = () => {
    const newBrandRow = { id: totalAddBrandRows.length + 1 };
    setTotalAddBrandRows([...totalAddBrandRows, newBrandRow]);
    setFormData(prevState => ({
        ...prevState,
        distrubutorProductObject: [
            ...prevState.distrubutorProductObject,
            { distributorId: '', programId: '', mrp: '', listingPrice: '', isDistActive: 1}
        ]
    }));
  };

  const addBrandDeleteRow = (id) => {
      const updatedBrandRows = totalAddBrandRows.filter(row => row.id !== id);
      setTotalAddBrandRows(updatedBrandRows);
      setFormData(prevState => ({
          ...prevState,
          distrubutorProductObject: prevState.distrubutorProductObject.filter((_, i) => i + 1 !== id)
      }));
  };

  console.log("formData ===", formData);

return (
    <>
   {isLoadingOn && <Loader />}
    <div className="container">
        <h3 className="programHead">{isEditMode ? "Update Data" : "Add Product"}</h3>
        <form onSubmit={handleSubmit}>
        <div className="row">
            <h4 className="programDetailsDekho">Product Details</h4>
            <hr />
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="productName" className="form-label">
                    Product Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Product Name"
                    className="form-control"
                    id="productName"
                    name="productName"
                    value={formData.productName}
                    onChange={handleUpdateChange}
                    required
                  />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="creditLimit" className="form-label">
                      Brand<span>*</span>
                  </label>
                  <Select
                      placeholder="Select"
                      name="brand"
                      options={userBrands}
                      onChange={(selectedOption) => handleAddBrandChange(selectedOption)}
                      required
                  />
                  {errors.brand && (
                      <div className="text-danger">{errors.brand}</div>
                  )}
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
                <div className="mb-3">
                    <label htmlFor="creditLimit" className="form-label">
                        Product Category<span>*</span>
                    </label>
                    <Select
                        placeholder="Select"
                        name="productCategoryId"
                        options={distributorProductType}
                        onChange={(selectedOption) => handleAddProductChange(selectedOption)}
                        required
                    />
                    {errors.productCategoryId && (
                        <div className="text-danger">{errors.productCategoryId}</div>
                    )}
                </div>
            </div>

            <div className="col-md-3 changeProductPro">
                <div className="mb-3">
                    <label htmlFor="creditLimit" className="form-label">
                        Product Sub Category
                    </label>
                    <Select
                        placeholder="Select"
                        name="subCategory"
                        options={distributorSubProductType}
                        onChange={(selectedOption) => handleAddSubProductChange(selectedOption)}
                        isDisabled={!distributorSubProductType || distributorSubProductType.length === 0}
                        value={distributorSubProductType.find(
                            option => option.label === formData.subCategory
                        ) || null}
                    />
                    {errors.subCategory && (
                        <div className="text-danger">{errors.subCategory}</div>
                    )}
                </div>
            </div>
        </div>

        <div className="row">
          <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="size" className="form-label">
                  Size<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Size"
                  className="form-control"
                  id="size"
                  name="size"
                  value={formData.size}
                  onChange={handleUpdateChange}
                  required
                />
            </div>
          </div>

          <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="color" className="form-label">
                  Color<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Color"
                  className="form-control"
                  id="color"
                  name="color"
                  value={formData.color}
                  onChange={handleUpdateChange}
                  required
                />
            </div>
          </div>

          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
                <label htmlFor="description" className="form-label">
                Description<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter description"
                  className="form-control"
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleUpdateChange}
                  required
                />
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="filePath1" className="form-label">
                    Image 1
                  </label>
                  <input
                      type="file"
                      accept='pdf'
                      placeholder="Enter"
                      className="form-control"
                      id="filePath1"
                      name="filePath1"
                      onChange={(event) => handleFileInputChange(event, 'filePath1')}
                      aria-describedby="textHelp"
                  />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="filePath2" className="form-label">
                   Image 2
                  </label>
                  <input
                      type="file"
                      accept='pdf'
                      placeholder="Enter"
                      className="form-control"
                      id="filePath2"
                      name="filePath2"
                      onChange={(event) => handleFileInputChange(event, 'filePath2')}
                      aria-describedby="textHelp"
                  />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="filePath3" className="form-label">
                    Image 3
                  </label>
                  <input
                      type="file"
                      accept='pdf'
                      placeholder="Enter"
                      className="form-control"
                      id="filePath3"
                      name="filePath3"
                      onChange={(event) => handleFileInputChange(event, 'filePath3')}
                      aria-describedby="textHelp"
                  />
              </div>
            </div>
          
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="isActive" className="form-label">
                      Product status<span>*</span>
                  </label>
                  <Select
                      placeholder="Select"
                      defaultValue={{ label: "Published", value: 1 }}
                      name="isActive"
                      options={statusOptions}
                      value={
                          formData.isActive !== undefined
                              ? {
                                    label: formData.isActive === 1 ? "Published" : "Not Published",
                                    value: formData.isActive,
                                }
                              : { label: "Published", value: 1 }
                      }
                      onChange={(selectedOption) => handleStatusChange(selectedOption)}
                  />
                  {errors.isActive && (
                      <div className="text-danger">{errors.isActive}</div>
                  )}
              </div>
            </div>
        </div>

        {/* ------------------------------------- Add Brand ------------------------------------ */}

        <>
          <h4 className='programDetailsDekho'>Add Distributors</h4>
          <hr />
            {totalAddBrandRows && totalAddBrandRows.map((row, index) => (
              <div key={row.id} className="row">
                  <div className="col-md-2 changeProductPro">
                      <div className="mb-3">
                          <label htmlFor="creditLimit" className="form-label">
                              Distributors
                          </label>
                          <Select
                              placeholder="Select"
                              name="distributorId"
                              options={distributorUserData}
                              onChange={(selectedOption) => handleAddDistributorChange(row.id, selectedOption, "programBrands")}
                          />
                          {errors.distributorId && (
                              <div className="text-danger">{errors.distributorId}</div>
                          )}
                      </div>
                  </div>

                  <div className="col-md-2 changeProductPro">
                      <div className="mb-3">
                          <label htmlFor="programId" className="form-label">
                              Program
                          </label>
                          <Select
                              placeholder="Select"
                              name="programId"
                              options={programData}
                              onChange={(selectedOption) => handleAddProgramChange(row.id, selectedOption, "programBrands")}
                          />
                          {errors.programId && (
                              <div className="text-danger">{errors.programId}</div>
                          )}
                      </div>
                  </div>

                  <div className="col-md-2 changeProductPro">
                    <div className="mb-3">
                        <label htmlFor="mrp" className="form-label">
                          MRP
                        </label>
                        <input
                            type="number"
                            step='any'
                            placeholder="Enter MRP"
                            className="form-control"
                            id="mrp"
                            name="mrp"
                            value={formData.mrp}
                            onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                            aria-describedby="textHelp"
                        />
                        {errors.mrp && (
                            <div className="text-danger">{errors.mrp}</div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-2 changeProductPro">
                    <div className="mb-3">
                        <label htmlFor="listingPrice" className="form-label">
                          Listing Price
                        </label>
                        <input
                            type="number"
                            step='any'
                            placeholder="Enter Listing Price"
                            className="form-control"
                            id="listingPrice"
                            name="listingPrice"
                            value={formData.listingPrice}
                            onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                            aria-describedby="textHelp"
                        />
                        {errors.listingPrice && (
                            <div className="text-danger">{errors.listingPrice}</div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-2 changeProductPro">
                      <div className="mb-3">
                          <label htmlFor="isDistActive" className="form-label">
                            Distributor Product Status
                          </label>
                          <Select
                              placeholder="Select"
                              name="isDistActive"
                              options={statusDistOptions}
                              // value={{ label: "Published", value: 1 } || statusDistOptions[0]}
                              onChange={(selectedOption) => handleDistributorProductStatusChange(row.id, selectedOption)}
                          />
                          {errors.isDistActive && <div className="text-danger">{errors.isDistActive}</div>}
                      </div>
                  </div>
                          
                  {index === 0 ?
                    (
                        <div className="col-md-1">
                            <div className="proPlusIconGreen" onClick={addBrandAddRow}>
                                <FaPlusCircle />
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="col-md-1">
                            <div className="deletedIcon" onClick={() => addBrandDeleteRow(row.id)}>
                                <FaTrash />
                            </div>
                        </div>
                    )
                  }
              </div>
            ))}
        </>

        {/* ------------------------------------------------------------------------------------------- */}

        <div className="text-center">
            <button type="submit" className="btn btn-success">
            {isEditMode ? "Update" : "Submit"}
            </button>
        </div>
        </form>
    </div>
    </>
);

};

export default ProductAddData;