import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch, FaFileUpload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import { FaCalendarAlt } from "react-icons/fa";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import Table from "../pages/ReactTable"
import {formatNumberWithCommasAndDecimals} from "../component/common"


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

function CreditLimit() {
  const [userCreditData, setUserCreditData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenMobileNumber, setModalIsOpenMobileNumber] =
    React.useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [selectedOption, setSelectedOption] = useState(" ");
  const [selectedOptionStatus, setSelectedOptionStatus] = useState();
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [lenderNameInfo, setLenderNameInfo] = useState([]);
  const [merchantCreditLimitStatus, setMerchantCreditLimitStatus] = useState(null);
  const [file, setFile] = useState(null)
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");

  const userRole = localStorage.getItem("USER_ROLE");
  
  const [formData, setFormData] = useState({
    creditLimitId: "",
    creditLimit: "",
    tenure: "",
    transactionFees: "",
    processingFees: "",
    userId: "",
    status: "",
    financierId: "",
  });

  const [addCreditLimitData, setAddCreditLimitData] = useState({
    // creditLimitId: -1,
    creditLimit: "",
    tenure: "",
    transactionFees: "",
    processingFees: "",
    mobileNumber: "",
  });

  const formatter = new Intl.NumberFormat("en-IN");
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Access the form data here and perform any necessary actions
    console.log("formdata----", formData);
    setIsLoadingOn(true);
    const res = await postData(`users/updateCreditLimit`, formData);
    setIsLoadingOn(false);
    if (res.statusCode == 200) {
      setIsOpen(false);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Updated successfully!",
      });
    }
    else{
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: res?.data?.errors.msg || 'Somthing went wrong',
      });
    }
  };

  const UploadCreditLimit = async (event) => {
    event.preventDefault();
    if (file) {
      const bulkUploadResponse = await postData(`users/insertCreditLimitByCSV`, {
        file: file
      })
      console.log("Check file ----", file);
      console.log("bulkUploadResponse", bulkUploadResponse);
      if (bulkUploadResponse.statusCode == 200) {

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Uploaded successfully!",
        });
      }
      else {
        console.log('No file selected.');
      }
    }
  }
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type !== 'text/csv') {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Select CSV file",
      });
      e.target.value = ''; // Clear the input
      return;
    }
    if (selectedFile) {
      const reader = new FileReader();
      // reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        setFile(base64String);
      };
      reader.readAsDataURL(selectedFile)
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };

  const handleInputChange = (e) => {
    if (merchantRefrenceType === 'Mobile Number' ||  "Credit Limit" ) {
        const value = e.target.value.replace(/[^0-9.]/g, '').slice(0, 10);
        setSearchBoxValue(value);
    } else {
        setSearchBoxValue(e.target.value);
    }
};

  const handelUpdateSubmit = async (event) => {
    if(!addCreditLimitData.creditLimit){
      Swal.fire({
        icon: "error",
        text: "Please enter credit limit",
      });
    }
    if(!addCreditLimitData.mobileNumber){
      Swal.fire({
        icon: "error",
        text: "Please enter Mobile Number",
      });
    }
    // if(!addCreditLimitData.tenure){
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please enter tenure",
    //   });
    // }
    // if(!addCreditLimitData.transactionFees){
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please enter Transaction fees",
    //   });
    // }
    // if(!addCreditLimitData.processingFees){
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please enter processing Fees",
    //   });
    // }
    event.preventDefault();
    let filteredData = {
      mobileNumber: addCreditLimitData.mobileNumber,
      creditLimit: addCreditLimitData.creditLimit,
      tenure: addCreditLimitData.tenure,
      transactionFees: addCreditLimitData.transactionFees,
      processingFees: addCreditLimitData.processingFees,
      financierId:formData.financierId,
    }
    console.log("Filteresd Credit Limit", filteredData);
    const res = await postData(`users/creditLimit`, filteredData);
    console.log(res);
    if (res.statusCode == 200) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: res?.message,
      });
    }
    else{
      Swal.fire({
        title:"Error!",
        text:res?.response?.data?.errors?.msg || "Something went wrong",
      })
    }
    if (res.statusCode === 401) {
      Swal.fire({
        icon: "error",
        title: "User Not Exist",
        text: "User Not Exists",
      });

    }
    

    // setIsOpen(false);
  };

    const handleGotoPage = (page) => {
      if (page < 0 || page >= pageCount) {
        setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
        setPageIndex(page);
    }
  };

  const handleSort = (column) => {
      let newOrderBy = "asc";
    
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    getLimitData(pageIndex, pageSize, column.id, newOrderBy);
  };



  // Function to handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setAddCreditLimitData({
      ...addCreditLimitData,
      [name]: value,
    });
  };
  const inputRef = useRef();

  const closeModal = () => {
    setIsOpen(false);
    setModalIsOpenMobileNumber(false);
  };
  const handelAddData = async (item) => {
    let tempData = {
      creditLimitId: item.id,
      creditLimit: item.creditLimit,
      tenure: item.tenure,
      transactionFees: item.transactionFees,
      processingFees: item.processingFees,
      userId: item.userId,
    };
    // console.log(tempData);
    setFormData(tempData);
    setIsOpen(true);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
      // Add more data as needed
    ],
    []
  );
  const history = useHistory();


  const getLimitData = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    // setIsLoadingOn(true);
    // const tempData = await getData("users/getCreditLimitList/1");
    // console.log("tempData---", tempData);
    // setIsLoadingOn(false);
    setIsLoadingOn(true);
    let apiUrl = `users/getCreditLimitList/1?page=${page + 1}&pageSize=${pageSize}`;
    
    if (sortBy && orderBy) {
        apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }
    const tempData = await getData(apiUrl)
    if (tempData.statusCode == 200) {
      setUserCreditData(tempData.data.records);
      setPageCount(tempData.data.pagination.totalPagesCount);
      setTotalRecords(tempData.data.pagination.totalRecords);
      setErrorMessage("");
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
    }
  }; 

   const getFinancierLender = async () => {
    let tempLenderData = [];
    setIsLoadingOn(true);
    const tempData = await getData("financier/getFinancierList");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      for (let index = 0; index < tempData.data.length; index++) {
        const element = tempData.data[index];
        if(element){
          tempLenderData.push({
            label:element.nameNBFC, value:element.id
          })
        }
      }
      setLenderNameInfo(tempLenderData);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
    }
  };
  
  useEffect(() => {
    getLimitData(pageIndex, pageSize, sortBy, orderBy);
    getFinancierLender();
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const handelAddCreditLitmi = () => {
    setModalIsOpenMobileNumber(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "User Ref. No.",
        accessor: "referenceNo",
        Cell: ({ cell }) => {
          return (
            <>
              <button
                title="View Details"
                className="viewButtonMean"
                onClick={() => {
                  // console.log("shfdbsdf",userRole);
                  if (userRole === "Admin" || userRole === "Operation" || userRole === "Tendative") {
                    handelAddData(cell.row.original);
                  }
                }}
              >
                {cell.row.original.userReferenceNumber}
              </button>
            </>
          );
        },
      },
      {
        Header: "Credit Limit",
        accessor: "creditLimit",
          Cell: ({ value }) => {
            const numericValue = Number(value);
            if (!isNaN(numericValue)) {
              return formatNumberWithCommasAndDecimals(numericValue);
            } else {
                return value || "-";
            }
        },
      },
      {
        Header: "Used Credit Limit",
        accessor: "usedCreditLimit",
        Cell: ({ value }) => {
          const numericValue = Number(value);
          if (!isNaN(numericValue)) {
            return formatNumberWithCommasAndDecimals(numericValue);
          } else {
              return value || "-";
          }
      },
      },
      {
        Header: "Avail Credit Limit",
        accessor: "availCreditLimit",
        Cell: ({ value }) => {
          const numericValue = Number(value);
          if (!isNaN(numericValue)) {
              return formatNumberWithCommasAndDecimals(numericValue);
          } else {
              return value || "-";
          }
      },
      },
      {
        Header: "Credit Limit Status",
        accessor: "crditLimitStatusName",
        Cell: ({ cell }) => {
          const verified = cell.row.original.crditLimitStatusName;
          return (
            <>
              {verified == "Processing" ? (
                <button style={{border: "none"}} className="badge badge-pill badge-warning">Processing</button>
              ) : verified == "Approved" ? (
                <button style={{border: "none"}} className="badge badge-pill badge-success">Approved</button>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "Tenure",
        accessor: "tenure",
      },
      {
        Header: "Transaction Fees",
        accessor: "transactionFees",
      },
      {
        Header: "Processing Fees",
        accessor: "processingFees",
      },
      {
        Header: "Name",
        accessor: "firstName",
      },
    ],
    [pageIndex,pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    // state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const resetFilters = () => {
    inputRef.current.value = "";
    setSelectedOption(null);
    setSelectedOptionStatus(null);
    setSearchBoxValue("");
    getLimitData();
  };

  const merchantOption = [
    // { value: "Reference No.", label: "Reference No." },
    { value: "Credit Limit", label: "Credit Limit" },
    { value: "Mobile Number", label: "Mobile Number" },
  ];
  const statusOption = [
    { value: " ", label: "All" },
    { value: "4", label: "Processing" },
    { value: "5", label: "Approved" },
  ];

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "Reference No.") {
      filterData = {
        userReferenceNumber: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "Mobile Number") {
      filterData = {
        mobileNumber: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "Mobile Number" && merchantCreditLimitStatus) {
      filterData = {
        status: merchantCreditLimitStatus,
        mobileNumber: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "Credit Limit"){
      filterData = {
        creditLimit: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "Credit Limit"  && merchantCreditLimitStatus) {
      filterData = {
        status: merchantCreditLimitStatus,
        creditLimit: searchBoxValue,
      };
    }
    else if (merchantCreditLimitStatus) {
      filterData = {
        status: merchantCreditLimitStatus,
      };
    } else {
      // alert("Please select any one option");
    }
    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    console.log("queryParams---", queryParams);
    console.log("filterData---", filterData);

    const tempData = await getData(`users/getCreditLimitList/1?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setUserCreditData(tempData.data.records);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
    }
  };

  const StatusValueCredit = [
    { label: "Limit Not Assigned", value: 25 },
    { label: "Limit Assigned", value: 26 },
    { label: "Limit Rejected", value: 27 },
  ];

  return (
    <>
      <div>
        {isLoadingOn ? <Loader /> : ""}
        <div className="row alignmentTable">
          <div className="col-md-9">
            <h3 className="tableHeaderMsg">Credit Limit</h3>
          </div>
          <div className="col-md-2" style={{marginTop:"-20px",marginBottom:"10px"}}>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              id="fileInput"
              className="form-control"
            />
          </div>
          <div className="col-md-1" style={{marginTop:"-20px",marginBottom:"10px"}}>
          <button style={{backgroundColor:"#f7674c",Color:"white"}}
              onClick={UploadCreditLimit}
              className="btn"
            >
              Bulk <FaFileUpload />
            </button>
          </div>
        </div>
        <div className="customHeaderTable">
          <div className="container-fluid">
            <div className="row alignmentTable">
              <div className="col-md-2">
                <Select
                  placeholder="Select Status"
                  name="role"
                  options={statusOption}
                  value={selectedOptionStatus}
                  onChange={(selected) => {
                    setMerchantCreditLimitStatus(selected.value);
                    setSelectedOptionStatus(selected);
                  }}
                />
              </div>
              <div className="col-md-2">
                <Select
                  placeholder="Select ..."
                  name="role"
                  options={merchantOption}
                  value={selectedOption}
                  onChange={(selected) => {
                    setMerchantRefrenceType(selected.value);
                    setSelectedOption(selected);
                  }}
                />
              </div>
              <div className="col-md-2">
                <input
                  type={merchantRefrenceType === "Mobile Number" || "Credit Limit" ? 'tel' : 'text'}
                  className="searchInputField"
                  ref={inputRef}
                  // onChange={(e) => {
                  //   setSearchBoxValue(e.target.value);
                  // }}
                  onChange={handleInputChange}
                  placeholder="Enter Details"
                  value={searchBoxValue}
                />
              </div>
              <div className="col-md-2 text-center" onClick={searchManualData}>
                <button className="searchTableData">
                  <FaSearch /> Search
                </button>
              </div>

              <div className="col-md-2 text-center" onClick={resetFilters}>
                <button className="resetTableData">
                  <HiRefresh /> Reset
                </button>
              </div>
              {userRole === "Admin" || userRole === "Operation" || userRole === "Tendative" ? (
                <div className="col-lg-2 text-end">
                  <button
                    className="btn btnAddCredit"
                    onClick={() => {
                      console.log("dfkjbf",userRole);
                      handelAddCreditLitmi();
                    }}
                  >
                    Add
                  </button>
                </div>
              ): null}

              {/* <div className="col-md-1" style={{marginTop:"-7px", paddingLeft:"30px"}}> 
              </div> */}
            </div>
          </div>
        </div>

        {userCreditData && 
        <Table 
        columns={columns} 
        data={userCreditData} 
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        handleGotoPage={handleGotoPage}
        errorMessage={errorMessage} 
        handleSort={handleSort}
        sortBy={sortBy}
        orderBy={orderBy}
        />}
      </div>
      {/* ------------- add credit Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <div className="modalUserImage">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Credit Limit
              </label>
              <input
                type="text"
                placeholder="Enter Credit Limit"
                className="form-control"
                id="creditLimit"
                name="creditLimit"
                value={formData.creditLimit}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
                  <label htmlFor="financierId" className="form-label">
                    Lender Name
                  </label>
                  <Select
                    placeholder="Select"
                    name="financierId"
                    required
                    options={lenderNameInfo}
                    value={lenderNameInfo.find(
                      (option) => option.value === formData.financierId
                    )}
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, financierId: selectedOption.value })
                    }
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="financierId" className="form-label">
                    Status
                  </label>
                  <Select
                    placeholder="Select"
                    name="status"
                    required
                    options={StatusValueCredit}
                    value={StatusValueCredit.find(
                      (option) => option.value === formData.status
                    )}
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, status: selectedOption.value })
                    }
                  />
                </div>

            {/* <div className="mb-3">
              <label htmlFor="tenure" className="form-label">
                Tenure
              </label>
              <input
                type="text"
                placeholder="Enter Tenure"
                className="form-control"
                id="tenure"
                name="tenure"
                value={formData.tenure}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div> */}
            {/* <div className="mb-3">
              <label htmlFor="transactionFees" className="form-label">
                Transaction Fees
              </label>
              <input
                type="number"
                placeholder="Enter Transaction Fees"
                className="form-control"
                id="transactionFees"
                name="transactionFees"
                value={formData.transactionFees}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div> */}
            {/* <div className="mb-3">
              <label htmlFor="processingFee" className="form-label">
                Processing fee
              </label>
              <input
                type="number"
                placeholder="Enter Processing fee"
                className="form-control"
                id="processingFees"
                name="processingFees"
                value={formData.processingFees}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div> */}
            <div className="text-center">
              <button
                type="submit"
                // onClick={handelSubmit}
                className="btn btn-success text-center"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* ------------- add credit Modal ---------------------- */}

      {/* ------------- add credit Mobile Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpenMobileNumber}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <div className="modalUserImage">
          <form onSubmit={handelUpdateSubmit}>
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Credit Limit
              </label>
              <input
                type="text"
                placeholder="Enter Credit Limit"
                className="form-control"
                id="creditLimit"
                name="creditLimit"
                value={addCreditLimitData.creditLimit}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="tenure" className="form-label">
                Tenure
              </label>
              <input
                type="text"
                placeholder="Enter Tenure"
                className="form-control"
                id="tenure"
                name="tenure"
                value={addCreditLimitData.tenure}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="transactionFees" className="form-label">
                Transaction Fees
              </label>
              <input
                type="number"
                placeholder="Enter Transaction Fees"
                className="form-control"
                id="transactionFees"
                name="transactionFees"
                value={addCreditLimitData.transactionFees}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="processingFee" className="form-label">
                Processing fee
              </label>
              <input
                type="number"
                placeholder="Enter Processing fee"
                className="form-control"
                id="processingFees"
                name="processingFees"
                value={addCreditLimitData.processingFees}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div> */}
            <div className="mb-3">
              <label htmlFor="mobileNo" className="form-label">
                Mobile Number
              </label>
              <input
                type="number"
                placeholder="Enter Mobile Number"
                className="form-control"
                id="mobileNo"
                name="mobileNumber"
                value={addCreditLimitData.mobileNumber}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
                  <label htmlFor="financierId" className="form-label">
                    Lender Name
                  </label>
                  <Select
                    placeholder="Select"
                    name="financierId"
                    required
                    options={lenderNameInfo}
                    value={lenderNameInfo.find(
                      (option) => option.value === formData.financierId
                    )}
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, financierId: selectedOption.value })
                    }
                  />
                </div>
            <div className="text-center">
              <button
                type="submit"
                onClick={() => handelUpdateSubmit}
                className="btn btn-success text-center"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* ------------- add credit Mobile Modal ---------------------- */}
    </>
  );
}

export default CreditLimit;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;


// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
