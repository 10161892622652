import React, { useEffect, useState } from "react";
import { getData, postData } from "../store/utils";
import { FaDownload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Loader from "../component/Loader";
import { format } from "date-fns";
import Select from "react-select";

const FosVisitList = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const handleFromDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setFromDate(formattedDate);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setToDate(formattedDate);
  };

  const getFosList = async()=>{
    setIsLoadingOn(true);
    const response = await getData(
      `employee/getEmployee?role=Sales&page=1&pageSize=500`
    );
    setIsLoadingOn(false);
    if (response.statusCode == 200) {
      let tempRecord = [];
      for(let index = 0; index < response?.data?.records?.length; index++){
        const element = response?.data?.records[index];
        if(element){
          tempRecord.push({label:element.name,value:element.id})
        }
      }
      console.log(tempRecord)
      setEmployeeList(tempRecord);
    }
  }

  useEffect(()=>{
    console.log("demo")
    console.log("demo")
    getFosList();
  },[])

  const fetchData = async () => {
    try {
      // console.log("fromDate", fromDate);

      if (fromDate !== null || toDate !== null) {
        const params = new URLSearchParams({
          fromDate,
          toDate,
        });

        if (selectedEmployee) {
          params.append("employeeId", selectedEmployee?.value);
        }
        
        const response = await getData(
          `merchantLead/visitFile?${params.toString()}`,
          { responseType: "blob" }
        );

        if (response.statusCode == 200) {
          const blob = new Blob([response.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `data_${Date.now()}.csv`;
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          if (url) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "File Download successfully!",
            });
          } else {
            Swal.fire({
              icon: "Error",
              title: "Error!",
              text: "Error In File Download!",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: `${response.data.errors.msg}` || 'Somthing went wrong',
          });
        }
      } else {
        alert("Please Select Valid Date");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      {/* <h3 className="tableHeaderMsg">Loan Tape</h3> */}
      <div className="customHeaderTable distable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <h6>
              <strong>Fos Visit Date :</strong>
            </h6>
            <div className="col-md-12">
              <div className="loanTapeChanges" style={{ alignItems: "center" }}>
              <div style={{ marginRight: 10, display:"flex", alignItems:"center" }}>
              <label>Employee :</label>&nbsp;
              <Select
                  placeholder="Select Employee"
                  name="employee"
                  options={employeeList}
                  value={selectedEmployee}
                  onChange={(selectedOption) => {
                    setSelectedEmployee(selectedOption);
                  }}
                />
                </div>
                <div style={{ marginRight: 10 }}>
                  <label>From :</label>&nbsp;
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={handleFromDateChange}
                    placeholderText="Select Start Date"
                    maxDate={new Date()}
                  />
                </div>
                <div style={{ marginRight: 30 }}>
                  <label>To :</label>&nbsp;
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={handleToDateChange}
                    placeholderText="Select End Date"
                    maxDate={new Date()}
                  />
                </div>
                <button
                  className="searchTableData"
                  style={{ marginRight: 15 }}
                  onClick={fetchData}
                >
                  <FaDownload /> Download Report
                </button>

                <button
                  className="resetTableData"
                  onClick={() => {
                    setFromDate(null);
                    setToDate(null);
                    setSelectedEmployee(null);
                  }}
                >
                  <HiRefresh /> Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isLoadingOn ? <Loader /> : null} */}
      {/* Display your table or other components here */}
    </>
  );
};

export default FosVisitList;
