import React, { useState, useMemo, useEffect } from "react";
import Table from "../pages/ReactTable";
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData, postData } from "../store/utils";
import Swal from "sweetalert2";
import { FaRegEye } from 'react-icons/fa';

const RepaymentWave = () => {
  const [fileData, setFileData] = useState([]);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState(new Set());

  const getLoanBurification = async (loanId) => {
    setIsLoadingOn(true);
    let apiUrl = `repayment/loanBifurcation?loanId=410`;

    const tempData = await getData(apiUrl);
    console.log("ActualData", tempData);
    setIsLoadingOn(false);
    
    if (tempData.statusCode === 200) {
      setFileData(tempData.data);  // The data from your API
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.message || "Something went wrong!!!"
      });
    }
  };

  const handleCheckboxChange = (rowId, extraChargeTypeId) => {
    setSelectedRows(prevSelectedRows => {
      const newSelectedRows = new Set(prevSelectedRows); // Create a new Set
      const rowData = { repaymentAmountId: rowId, extraChargeTypeId }; // Object to track the selection
      const existingItem = [...newSelectedRows].find(item => item.repaymentAmountId === rowId && item.extraChargeTypeId === extraChargeTypeId);

      if (existingItem) {
        newSelectedRows.delete(existingItem); // Remove the row if it's already selected
      } else {
        newSelectedRows.add(rowData); // Add the row if it's not selected
      }
      return newSelectedRows; // Return the updated Set
    });
  };

  const handleSubmit = async () => {
    if (selectedRows.size === 0) {
      Swal.fire("Error!", "Please select at least one item.", "error");
      return;
    }

    const waveOffList = [...selectedRows].map(row => ({
      extraChargeTypeId: row.extraChargeTypeId,
      repaymentAmountId: row.repaymentAmountId,
    }));

    const payload = {
      loanId: 410, 
      waveOffList: waveOffList
    };

    try {
      const response = await postData('repayment/repaymentWaveOff', payload);
      if (response.statusCode === 200) {
        Swal.fire({
            title: "Success!",
            text: "Data submitted successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            window.location.reload(); 
          });
      } else {
        Swal.fire("Error!", response?.message || "Failed to submit data.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "Something went wrong with the API call.", "error");
    }
  };

  useEffect(() => {
    getLoanBurification();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.index + 1 + pageIndex * pageSize}.
          </span>
        ),
      },
      {
        Header: "Select",
        accessor: "select",
        Cell: ({ cell }) => {
          const rowId = cell.row.original.repaymentAmountId; // Assuming unique repaymentAmountId
          const extraChargeTypeId = cell.row.original.extraChargeTypeId; // Get extraChargeTypeId from row data
          return (
            <input
              type="checkbox"
              checked={[...selectedRows].some(
                row => row.repaymentAmountId === rowId && row.extraChargeTypeId === extraChargeTypeId
              )} // Check if the row is selected
              onChange={() => handleCheckboxChange(rowId, extraChargeTypeId)} // Handle checkbox click
            />
          );
        },
      },
      {
        Header: "Repay ID",
        accessor: "repaymentAmountId",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.repaymentAmountId}
          </span>
        ),
      },
      {
        Header: "Pending Type",
        accessor: "pendingType",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.pendingType}
          </span>
        ),
      },
      {
        Header: "Pending Repayment Amount",
        accessor: "pendingRepaymentAmount",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.pendingRepaymentAmount ?? "N/A"} {/* Formatting to 2 decimals */}
          </span>
        ),
      },
      {
        Header: "Extra Charge Type ID",
        accessor: "extraChargeTypeId",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.extraChargeTypeId ?? "N/A"} {/* Display N/A if null */}
          </span>
        ),
      },
    ],
    [pageIndex, pageSize, selectedRows]
  );

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Loan Wave-Off </h3>
      <div className="orderListDataFooter">
        {fileData.length > 0 ? (
          <Table columns={columns} data={fileData} />
        ) : (
          <p>No data available</p>
        )}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button
                onClick={handleSubmit}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </div>
      </div>
    </>
  );
};

export default RepaymentWave;
