import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../css/makeTable.css'
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination,
    useSortBy,
} from "react-table";
import { getData } from '../store/utils';
import Loader from '../component/Loader';
import { useHistory } from 'react-router-dom';
import { FaSearch, } from 'react-icons/fa';
import { HiRefresh } from "react-icons/hi";
import Select from "react-select";
import Swal from "sweetalert2";

function LoanRepayment() {
    const history = useHistory();

    const [userLoanData, setUserLoanData] = useState([]);
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [merchantRefrenceType, setMerchantRefrenceType] = useState();
    const [searchBoxValue, setSearchBoxValue] = useState();
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        getDataLoanRepayment();
    }, [])

    const getDataLoanRepayment = async () => {
        setIsLoadingOn(true);
        const tempData = await getData("loan/getLoan/1");
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setUserLoanData(tempData.data);
        }
        else{
            Swal.fire({
                title:"Error!",
                text:tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    };

    const handleInputChange = (e) => {
        if (merchantRefrenceType === "Order Id" || "Loan Amount") {
            const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
            setSearchBoxValue(value);
        } else {
            setSearchBoxValue(e.target.value);
        }
    };

    const handelViewData = (loanId) => {
        history.push({
            pathname: '/LoanRepaymentDetails',
            state: { loanId: loanId },
        });
    }

    const data = useMemo(
        () => [
            { id: 1, name: 'John Doe', age: 25, city: 'New York', mobileNo: '9988776786' },
        ],
        []
    );

    const columns = useMemo(
        () => [
            {
                Header: "Sr. No.",
                accessor: (row, i) => i + 1,
                Cell: ({ cell }) => (
                  <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1}.</span>
                ),
            },
            {
                Header: 'Order Id',
                accessor: 'orderId',
                Cell: ({ cell }) => {
                    return (
                        <>
                            <button title='View Details' className='viewButtonMean' onClick={() => handelViewData(cell.row.original.id)}>
                                {cell.row.original.orderId != null ? cell.row.original.orderId : cell.row.original.id}</button>
                        </>
                    );
                },
            },
            {
                Header: 'Loan Amount',
                accessor: 'amount'
            },
            {
                Header: 'Disbursed Amount (to distributor)',
                accessor: 'disbursedAmount'
            },
            {
                Header: 'Final Principal',
                accessor: 'principal',
            },
            {
                Header: 'EMI Amount',
                accessor: 'emiAmount'
            },
            {
                Header: 'CD%',
                accessor: 'cashDiscountAmount'
            },
            {
                Header: 'IF%',
                accessor: 'initiationFeesAmount'
            },
            {
                Header: 'VAS %',
                accessor: 'valueAddedServiceAmount'
            },
            {
                Header: 'FF%',
                accessor: 'facilitationFeesAmount'
            },
            {
                Header: 'Rate of Interest',
                accessor: 'rateOfInterest'
            },
            {
                Header: 'Monthly ROI',
                accessor: 'monthlyROI'
            },
            {
                Header: 'Tenure',
                accessor: 'tenure'
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );

    const merchantOption = [
        { value: 'Order Id', label: 'Order Id' },
        { value: 'Loan Amount', label: 'Loan Amount' },
    ]

    const searchManualData = async () => {
        let filterData = {};

        if (merchantRefrenceType === "Order Id") {
            filterData = {
                orderId: searchBoxValue,
            };
        } else if (merchantRefrenceType === "Loan Amount") {
            filterData = {
                loanAmount: searchBoxValue,
            };
            
        }
        else {
            alert("Please select any one option");
        }

        setIsLoadingOn(true);

        const queryParams = new URLSearchParams(filterData).toString();

        console.log("queryParams---", queryParams)
        console.log("filterData---", filterData)

        const tempData = await getData(`loan/getLoan/1?${queryParams}`);
        console.log("tempData---", tempData);

        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            setUserLoanData(tempData.data);
        }
        else{
            Swal.fire({
                title:"Error!",
                text:tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    };
    const inputRef = useRef();

    const resetFilters = () => {
        inputRef.current.value = '';
        setSelectedOption(null);
        setSearchBoxValue('');
        getDataLoanRepayment();
    };

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}

            {/* <div className='merchantDetailsHeading'>
                <h4 style={{ fontWeight: "700" }}>Loan Repayment Details</h4>
            </div> */}
             <h3 className="tableHeaderMsg">Loan Repayment Details</h3>
            <div className='customHeaderTable'>
                <div className="container-fluid">
                    <div className="row alignmentTable">
                        <div className="col-md-2">
                            <Select
                                placeholder="Select Status"
                                name="role"
                                options={merchantOption}
                                value={selectedOption}
                                onChange={(selected) => {
                                    setMerchantRefrenceType(selected.value);
                                    setSelectedOption(selected);
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <input 
                                type={merchantRefrenceType === "Order Id" || "Loan Amount" ? 'tel' : 'text'}
                                className='searchInputField'
                                ref={inputRef}
                                // onChange={(e) => {
                                //     setSearchBoxValue(e.target.value);
                                // }}
                                onChange={handleInputChange}
                                placeholder='Enter Details'
                                value={searchBoxValue}
                            />
                        </div>
                        <div className="col-md-2 text-center" onClick={searchManualData}>
                            <button className='searchTableData'><FaSearch /> Search</button>
                        </div>

                        <div className="col-md-2 text-center" onClick={resetFilters}>
                            <button className='resetTableData' ><HiRefresh /> Reset</button>
                        </div>

                    </div>
                </div>
            </div>
            {userLoanData && <Table columns={columns} data={userLoanData} />}
        </div>
    )
}

export default LoanRepayment;

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{" "}
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <div>
            <input
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <input
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "70px",
                    marginRight: "0.5rem",
                }}
            />
            to
            <input
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "70px",
                    marginLeft: "0.5rem",
                }}
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy, //sort data
        usePagination //use pagination
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    //const firstPageRows = rows.slice(0, 10);

    return (
        <>
            <div>

                <table {...getTableProps()} className="dashboardTable">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="tableHeader"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? "↓"
                                                    : "↑"
                                                : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* <tr>
                      <th
                        colSpan={visibleColumns.length}
                        style={{
                          textAlign: 'left',
                        }}
                      >
                      </th>
                    </tr> */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="tableTd">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    {/* <tfoot>
                    {footerGroups.map(group => (
                      <tr {...group.getFooterGroupProps()}>
                        {group.headers.map(column => (
                          <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                      </tr>
                    ))}
                  </tfoot> */}
                </table>
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {">"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </button>{" "}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records <strong>{rows.length}</strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{" "}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(page);
                                    }}
                                    className="gotoPage"
                                />
                            </span>{" "}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[10, 20, 30, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <br />
            </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
