import React, { useState, useEffect, useMemo } from "react";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import Loader from "../component/Loader";
import Select from "react-select";
import { getData, postData } from "../store/utils";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import "../css/makeTable.css";

const Financier = ({ onDelete }) => {
  const [financierUserData, setFinancierUserData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const history = useHistory();

  const getFinancierList = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("users/getFinancierDeatails/nothing");
    console.log("ActualData", tempData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      const filteredData = tempData.data.records.filter((item) => item.deleted === 0);
      console.log("FilteredData", filteredData);
      setFinancierUserData(filteredData);
    }
  };

  const deleteData = async (id) => {
    const data = {
      financierId: id,
    };
    try {
      const response = await postData(`users/deleteFinancierDeatails`, data);
      console.log("Delete", response);
      if (response.statusCode === 200) {
        return true;
      } else {
        console.error("Error response from server:", response);
        return true;
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      return false;
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const success = await deleteData(id);
        if (success) {
          setFinancierUserData((prevData) => {
            const updatedData = prevData.filter((item) => item.id !== id);
            console.log("Updated Data:", updatedData);
            return updatedData;
          });
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
        } else {
          Swal.fire("Failed!", "Your data could not be deleted.", "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  useEffect(() => {
    getFinancierList();
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1}.</span>
        ),
      },
      {
        Header: "NBFC Name",
        accessor: "nameNBFC",
      },
      {
        Header: "NBFC Address",
        accessor: "addressNBFC",
      },
      {
        Header: "Brand Logo",
        accessor: "LogoNBFC",
        Cell: ({ cell: { value } }) => (
          <img
            src={value}
            alt="Logo"
            style={{ width: "75px", height: "50px" }}
          />
        ),
      },
      {
        Header: "Grievance Name",
        accessor: "grivenceName",
      },
      {
        Header: "Email",
        accessor: "grivenceEmail",
      },
      {
        Header: "Contact",
        accessor: "grivenceMobileno",
      },
      {
        Header: "Address",
        accessor: "grivenceAddress",
      },
      {
        Header: "Priority",
        accessor: "priority",
      },
      {
        Header: "Approval Needed",
        accessor: "isApprovalNeeded",
      },
      {
        Header: "Max. Order Approval Amount",
        accessor: "maxOrderApprovalAmount",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ cell }) => {
          const { id } = cell.row.original;
          return (
            <>
              <div className="loanIdDownloadBtnDiv d-flex justify-content-between align-items-center">
                <button
                  className="btn-sm btn-warning"
                  onClick={() => {
                    const { id } = cell.row.original;

                    window.open(`/EditFinancier?id=${id}`, "_blank");
                  }}
                >
                  <span>Edit</span>
                </button>
                <button
                  className="btn-sm btn-danger"
                  onClick={() => {
                    handleDelete(id);
                  }}
                >
                  <span>Delete</span>
                </button>
              </div>
            </>
          );
        },
      },
    ],
    []
  );
  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );
  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Financier List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div
            className="row alignmentTable"
            style={{ textAlign: "center", fontWeight: "500" }}
          >
            <div
              className="col-md-12 buttonAlignmentProgram"
              style={{ justifyContent:"end", fontWeight: "500" }}
            >
              <button
                className="btn btnAddCredit"
                onClick={() => {
                  history.push("/AddFinancier");
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {financierUserData && (
          <Table columns={columns} data={financierUserData} />
        )}
      </div>
    </>
  );
};

export default Financier;

function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className="orderTableFooter">
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}
