import React, { useState } from 'react';
import { getData, postData } from '../store/utils';
import { FaDownload } from 'react-icons/fa';
import { HiRefresh } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Loader from '../component/Loader';
import { formatDate } from '../component/common';
import { format } from 'date-fns';

const OrderSubMenuFile = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [data, setData] = useState(null);
  const [isLoadingOn, setIsLoadingOn] = useState(false);

  const handleFromDateChange = (date) => {
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
    setFromDate(formattedDate);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
    setToDate(formattedDate);
  };

  console.log("date++++", fromDate, toDate)

  const fetchData = async () => {

    try {

      const filterdata = {
        fromDate: fromDate,
        toDate: toDate,
      }
      setIsLoadingOn(true);
      const response = await postData(`users/orderExport`, filterdata);
      setIsLoadingOn(false);
      if (response.statusCode == 200) {
        const file = window.open(response.data.Location);
        if (file) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "File Download successfully!",
          });
        } else {
          Swal.fire({
            icon: "Error",
            title: "Error!",
            text: "Error In File Download!",
          });
        }

      } else {
        Swal.fire({
          icon: "error",
          text: `${response.data.errors.msg}`,
        });
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDownload = () => {
    if (data) {
      const fileName = 'order_data.csv'; // Example file name
      const csv = convertDataToCSV(data); // Assuming convertDataToCSV is a function to convert data to CSV format
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  };

  const convertDataToCSV = (data) => {
    // Example function to convert data to CSV format
    const header = Object.keys(data[0]).join(',');
    const csv = data.map(row => Object.values(row).join(',')).join('\n');
    return `${header}\n${csv}`;
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ''}
      <h3 className='tableHeaderMsg'>Orders File</h3>
      <div className='customHeaderTable distable'>
        <div className="container-fluid">
          <div className="row alignmentTable">
            <h6><strong>Order Date :</strong></h6>
            <div className="col-md-3">
              <label>From :</label>&nbsp;&nbsp;
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={fromDate ? new Date(fromDate) : null}
                onChange={handleFromDateChange}
                placeholderText="Select Start Date"
                maxDate={new Date()}
              />
            </div>
            <div className="col-md-3">
              <label>To :</label>&nbsp;&nbsp;
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={toDate ? new Date(toDate) : null}
                onChange={handleToDateChange}
                placeholderText="Select End Date"
                maxDate={new Date()}
              />
            </div>
            <div className="col-md-3 text-center">
              <button className='searchTableData' onClick={fetchData}><FaDownload /> Order </button>
            </div>
            <div className="col-md-2 text-center">
              <button className='resetTableData' onClick={() => { setFromDate(null); setToDate(null); }}><HiRefresh /> Reset</button>
            </div>
          </div>
        </div>
      </div>
      {/* {isLoadingOn ? <Loader /> : null} */}
      {/* Display your table or other components here */}
    </>
  );
};

export default OrderSubMenuFile;
