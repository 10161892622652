    import React, { useEffect, useState, useMemo } from "react";
    import { getData } from "../../store/utils";
    import Swal from "sweetalert2";
    import Loader from "../../component/Loader";
    import Table from "../../pages/ReactTable";  
    import { formatDateTimeSecond } from "../../component/common";

    const MerchantApiLogsDetails = () => {
      const [merchantUserData, setMerchantUserData] = useState([]);
      const [isLoadingOn, setIsLoadingOn] = useState(false);
      const [pageCount, setPageCount] = useState(0);
      const [totalRecords, setTotalRecords] = useState(0);
      const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
      const [pageSize, setPageSize] = useState(10); // Default page size
      const [errorMessage, setErrorMessage] = useState("");
      const [sortBy, setSortBy] = useState(""); 
      const [orderBy, setOrderBy] = useState("");

      const urlSearchParams = new URLSearchParams(window.location.search);
      const userId = urlSearchParams.get("userId");

      const handleGotoPage = (page) => {
        if (page < 0 || page >= pageCount) {
          setErrorMessage(`No data to display`);
        } else {
          setErrorMessage("");
            setPageIndex(page);
        }
      };
    
      const handleSort = (column) => {
        let newOrderBy = "asc";
        
        if (sortBy === column.id) {
          newOrderBy = orderBy === "asc" ? "desc" : "asc";
        }
        setSortBy(column.id);
        setOrderBy(newOrderBy);
        merchantGetData(pageIndex, pageSize, column.id, newOrderBy);
      };

      const merchantGetData = async (userId, page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
        setIsLoadingOn(true);
        let apiUrl = `users/apiLogs/${userId}?page=${page + 1}&pageSize=${pageSize}`;
        
          if (sortBy && orderBy) {
              apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
          }
        const tempData = await getData(apiUrl)
        setIsLoadingOn(false);
        if (tempData.statusCode === 200) {
          setMerchantUserData(tempData.data.records);
          setPageCount(tempData.data.pagination.totalPagesCount);
          setTotalRecords(tempData.data.pagination.totalRecords);
          setErrorMessage("");
        }
        else{
          Swal.fire({
            title:"Error!",
            text:tempData?.data?.errors.msg || "Something went wrong!!!"
          })
        }
      };
    
      useEffect(() => {
        if (userId) {
          merchantGetData(userId, pageIndex, pageSize, sortBy, orderBy);
        }
      }, [userId, pageIndex, pageSize, sortBy, orderBy]);

      const columns = useMemo(
        () => [
          {
            Header: "Sr. No.",
            accessor: (row, i) => i + 1,
            Cell: ({ cell }) => <span style={{ fontWeight: "600", textAlign: "center"}}>{cell.row.index + 1}{"."}</span>,
          },
          {
            Header: "API Name",
            accessor: "apiName",
            Cell: ({ cell }) => <span style={{ fontWeight: "600" }}>{cell.value}</span>,
          },
          {
            Header: "Request",
            accessor: "request",
            Cell: ({ cell }) => {
              const [expanded, setExpanded] = useState(false);
              const content = cell.value || " - ";
              const displayContent = expanded ? content : content.slice(0, 300);
      
              return (
                <div style={{ maxWidth: "600px", whiteSpace: "normal", wordWrap: "break-word" }}>
                  {displayContent}
                  {content.length > 300 && !expanded && (
                    <span
                      onClick={() => setExpanded(true)}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      {" "}
                      Read more
                    </span>
                  )}
                </div>
              );
            },
          },
          {
            Header: "Response",
            accessor: "response",
            Cell: ({ cell }) => {
              const [expanded, setExpanded] = useState(false);
              const content = (cell.value === "undefined" || cell.value === null) ? " - " : cell.value;
              const displayContent = expanded ? content : content.slice(0, 300);
      
              return (
                <div style={{ maxWidth: "600px", whiteSpace: "normal", wordWrap: "break-word" }}>
                  {displayContent}
                  {content.length > 300 && !expanded && (
                    <span
                      onClick={() => setExpanded(true)}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      {" "}
                      Read more
                    </span>
                  )}
                </div>
              );
            },
          },             
          {
            Header: "Created date",
            accessor: "createdAt",
            Cell: ({ cell }) => {
              return (
                <>
                  <h7>{formatDateTimeSecond(cell.row.original.createdAt)}</h7>
                </>
              );
            },
          },  
        ],
        [pageIndex,pageSize]
      );
      
      return (
        <>
          {isLoadingOn ? <Loader /> : ""}
          <div className="orderListDataFooter">
        {merchantUserData.length > 0 && (
          <Table 
            columns={columns} 
            data={merchantUserData} 
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            handleGotoPage={handleGotoPage}
            errorMessage={errorMessage} 
            handleSort={handleSort}
            sortBy={sortBy}
            orderBy={orderBy}
          />
        )}
      </div>
        </>
      );
    };

    export default MerchantApiLogsDetails;