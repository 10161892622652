import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import Select from "react-select";
import { formatDate, formatDateTimeSecond } from "../component/common";
import { useState } from "react";
import { useEffect } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    maxWidth: "50%",
  },
};

const statusOptions = [
  { label: "Pending", value: 3 },
  { label: "Approved", value: 5 },
  { label: "Reject", value: 13 },
];

const LeaveRequestActionModal = ({
  modalIsOpen,
  closeModal,
  leaveRequestData,
  handelSubmit,
}) => {
  const [statusValue, setStatusValue] = useState(3);
  const [approverCommentValue, setApproverCommentValue] = useState(null);

  useEffect(() => {
    setStatusValue(leaveRequestData.status);
    setApproverCommentValue(leaveRequestData.approverComment);
  }, [leaveRequestData]);
  
  return (<>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Leave Request Action"
    >
      <button
        className="modalCloseBtn"
        onClick={closeModal}
      >
        <FaRegTimesCircle />
      </button>
      <h4 className="pleaseSelectStatus">Leave Request Detail</h4>
      <div className="modalUserPaymentStatusAddBrand">
        <form>
          <div className="row">
            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="employeeName" className="form-label">
                  Employee Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="employeeName"
                  name="employeeName"
                  value={leaveRequestData.employeeName}
                  readOnly
                />
              </div>
            </div>

            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="employeeComment" className="form-label">
                  Employee Comment
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="employeeComment"
                  name="employeeComment"
                  value={leaveRequestData.employeeComment}
                  rows={1}
                  readOnly
                />
              </div>
            </div>

            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="fromDate" className="form-label">
                  From Date
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fromDate"
                  name="fromDate"
                  value={leaveRequestData.fromDate ? formatDate(leaveRequestData.fromDate) : ''}
                  readOnly
                />
              </div>
            </div>
            
            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="toDate" className="form-label">
                  To Date
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="toDate"
                  name="toDate"
                  value={leaveRequestData.toDate ? formatDate(leaveRequestData.toDate) : ''}
                  readOnly
                />
              </div>
            </div>
            
            {statusValue != 3 && <>
              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="approverName" className="form-label">
                    Approver Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="approverName"
                    name="approverName"
                    value={leaveRequestData.approverName}
                    readOnly
                  />
                </div>
              </div>
              
              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="approverActionTimestamp" className="form-label">
                    Approver Action Timestamp
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="approverActionTimestamp"
                    name="approverActionTimestamp"
                    value={leaveRequestData.approverActionTimestamp ? formatDateTimeSecond(leaveRequestData.approverActionTimestamp) : ''}
                    readOnly
                  />
                </div>
              </div>
            </>}

            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <Select
                  placeholder="Select Status"
                  id="status"
                  name="status"
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === statusValue
                  )}
                  onChange={(selectedOption) => setStatusValue(selectedOption.value)}
                />
              </div>
            </div>

            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="approverComment" className="form-label">
                  Approver Comment
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="approverComment"
                  name="approverComment"
                  value={approverCommentValue}
                  onChange={(e) => setApproverCommentValue(e.target.value)}
                />
              </div>
            </div>

            <div className="text-center">
              <button
                type="button"
                className="btn btn-success w-25 mt-2 text-center"
                onClick={() => {
                  handelSubmit({
                    leaveRequestId: leaveRequestData.id,
                    status: statusValue,
                    approverComment: approverCommentValue,
                  });
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </>);
};

export default LeaveRequestActionModal;