import React, { useState } from 'react';
import './Login.css';
import { useHistory } from 'react-router-dom';
import { postData } from '../store/utils';
import Loader from '../component/Loader';
import Swal from "sweetalert2";

function ForgetPassword() {
    const [username, setUsername] = useState('');
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        let tempDocument = {
            emailId: username,
        };

        setIsLoading(true);
        const tempData = await postData(`employee/forgotEmployeePassword`, tempDocument);
        setIsLoading(false);
        if (tempData.statusCode === 200) {
            Swal.fire("Success!", "Your password successfully send your Email ID", "success");
            history.push("/login")
        }
        else{
            alert('somthing went wrong')
        }
    }

    return (
        <>
            {isLoading ? <Loader /> : ""}
            <div className='loginBodyPage'>
                <div className="wrapper">
                    <form onSubmit={handleSubmit}>
                        <h1>Forget Password</h1>
                        <div className="input-box">
                            <input type="text" placeholder="Username" required=""
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <i className="bx bxs-user" />
                        </div>
                        <button type="submit" className="btn">
                            Submit
                        </button>
                    </form>
                </div>
            </div>

        </>
    );
}

export default ForgetPassword;