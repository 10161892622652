import React, { useState } from "react";
import { getData, postData } from "../store/utils";
import { FaDownload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Loader from "../component/Loader";
import { format } from "date-fns";
import Select from "react-select";

const RepaymentExport = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [reportTypeError, setReportTypeError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [repaymentActiveStatus, setRepaymentActiveStatus] = useState();
  const [repaymentReportType, setRepaymentReportType] = useState(null);

  const handleFromDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setFromDate(formattedDate);
    setFromDateError(!date);
    setToDateError(!date);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setToDate(formattedDate);
  };

  const validateDates = () => {
    setFromDateError(!fromDate);
    setToDateError(!toDate);
    setReportTypeError(!repaymentReportType);
  };

  console.log("fromDate+++++", fromDate, toDate);

  const fetchData = async () => {
    validateDates();
    try {
      let filterData = {
        fromDate: fromDate,
        toDate: toDate,
        status: repaymentActiveStatus,
        type: repaymentReportType,
      };
      if (fromDate !== null || toDate !== null || repaymentReportType != null) {
        console.log("Testing Download file date ");
        setIsLoadingOn(true);
        const response = await postData(
          `repayment/repaymentExport`,
          filterData
        );
        setIsLoadingOn(false);
        if (response.statusCode == 200) {
          // console.log("Repayment eXPORT", response.data.Location);
          const file = window.open(response.data.Location);
          if (file) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "File Download successfully!",
            });
          } else {
            Swal.fire({
              icon: "Error",
              title: "Error!",
              text: "Error In File Download!",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: `${response.data.errors.msg}`,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Repayment Export </h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="loanTapeChanges loanRepaymentExportSec">
              <div className="col-md-2">
                <div style={{ maxWidth: "90%" }}>
                  <label>Report Type:</label>&nbsp;
                  <Select
                    placeholder="Select.."
                    name="reportType"
                    options={[
                      { value: "dueDate", label: "Due date" },
                      { value: "paymentDate", label: "Payment Date" },
                    ]}
                    onChange={(e) => {
                      setRepaymentReportType(e.value);
                    }}
                  />
                  {reportTypeError && (
                    <div style={{ color: "red" }}>
                      Please report type
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-2">
                <div style={{ maxWidth: "90%" }}>
                  <label>From Due Date:</label>&nbsp;
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={handleFromDateChange}
                    placeholderText="Select Start Date"
                    // maxDate={new Date()}
                  />
                  {fromDateError && (
                    <div style={{ color: "red" }}>
                      Select start date
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-2">
                <label>To Due Date:</label>&nbsp;
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDateChange}
                  placeholderText="Select End Date"
                  // maxDate={new Date()}
                />
                {toDateError && (
                  <div style={{ color: "red" }}>Select end date</div>
                )}
              </div>
              <div className="col-md-2">
                <label>Status:</label>&nbsp;
                <Select
                  placeholder="Select.."
                  name="role"
                  options={[
                    { value: "", label: "All" },
                    { value: "15", label: "Pending" },
                    { value: "16", label: "Paid" },
                    { value: "17", label: "Partial Paid" },
                  ]}
                  // value={repaymentActiveStatus}
                  onChange={(e) => {
                    setRepaymentActiveStatus(e.value);
                  }}
                />
              </div>
              <div className="col-md-2 text-center">
                <button className="searchTableData" onClick={fetchData}>
                  <FaDownload /> Export
                </button>
              </div>
              <div className="col-md-2 text-center">
                <button
                  className="resetTableData"
                  onClick={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                >
                  <HiRefresh /> Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepaymentExport;
