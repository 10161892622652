import React from 'react'

function TopShowMessage(props) {
    return (
        <>
            <div style={{ position: "relative", zIndex:99, transition:"all 0.5s" }}>
                <div className='uploadFileMsg'>
                    <h5>{props.message}</h5>
                </div>
            </div>
        </>
    )
}

export default TopShowMessage