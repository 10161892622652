import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../../component/TopShowMessage";
import { formatDateTimeString } from "../../component/common";
import Swal from "sweetalert2";
import Table from "../../pages/ReactTable";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    maxWidth: "50%",
  },
};

function CouponList() {
  const [couponData, setCouponData] = useState();
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [userCouponData, setUserCouponData] = useState();
  const userRole = localStorage.getItem("USER_ROLE");
  const [formData, setFormData] = useState({
    discountFor: "",
    discountPercentage: "",
    promotionalCode: "",
    maxDiscount: "",
    maxCount: "",
    couponId: "",
    couponStartDate: "",
    couponEndDate: "",
    description: "",
    couponType: "",
    discountCriteria: [
      {
        fieldName: "",
        operation: "",
        value: "",
      },
    ],
  });

  // console.log("userCouponData+++",userCouponData)

  useEffect(() => {
    if (userCouponData) {
      const formatDate = (inputDate) => {
        if (!inputDate) return "";
        const [day, month, year] = inputDate.split("-");
        return `${year}-${month}-${day}`;
      };
      setFormData((prevData) => ({
        ...prevData,
        discountFor: userCouponData.discountFor || "",
        discountPercentage: userCouponData.discountPercentage || "",
        promotionalCode: userCouponData.promotionalCode || "",
        maxDiscount: userCouponData.maxDiscount || "",
        couponId: userCouponData.couponId || "",
        maxCount: userCouponData.maxCount || "",
        isActive: userCouponData.isActive || "",
        description: userCouponData.description || "",
        couponStartDate: formatDate(userCouponData.couponStartDate),
        couponEndDate: formatDate(userCouponData.couponEndDate),
        couponType: userCouponData.couponType || "",
        discountCriteria:
          userCouponData.discountCriteria || prevData.discountCriteria,
      }));
    }
  }, [userCouponData]);

  const handleInputChange = (e, index = null, field = null) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      if (index !== null && field !== null) {
        const updatedCriteria = [...prevData.discountCriteria];
        updatedCriteria[index][field] = value;
        return { ...prevData, discountCriteria: updatedCriteria };
      }
      return {
        ...prevData,
        [name]: name === "promotionalCode" ? value.toUpperCase() : value,
      };
    });
  };

  const handleDropdownChange = (name, selectedOption) => {
    setFormData((prevData) => ({ ...prevData, [name]: selectedOption.value }));
  };

  const handleDropdownInnerChange = (index, fieldName, selectedOption) => {
    if (!selectedOption || !selectedOption.value) {
      console.error("Invalid selectedOption:", selectedOption);
      return;
    }

    setFormData((prevData) => {
      const updatedCriteria = [...prevData.discountCriteria];
      updatedCriteria[index][fieldName] = selectedOption.value;
      return { ...prevData, discountCriteria: updatedCriteria };
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.discountFor) {
      errors.discountFor = "Please select discount on";
    }
    if (!formData.isActive) {
      errors.isActive = "Please select status";
    }
    if (!formData.discountPercentage) {
      errors.discountPercentage = "Please enter discount Percentage";
    }
    if (!formData.promotionalCode) {
      errors.promotionalCode = "Please enter promotional code";
    }
    if (!formData.maxDiscount) {
      errors.maxDiscount = "Please enter max discount";
    }
    if (!formData.maxCount) {
      errors.maxCount = "Please enter max count";
    }
    if (!formData.couponStartDate) {
      errors.couponStartDate = "Please select Start Date";
    }
    if (!formData.couponEndDate) {
      errors.couponEndDate = "Please select End Date";
    }
    if (!formData.couponType) {
      errors.couponType = "Please select coupon type";
    }
    if (!formData.description) {
      errors.description = "Please enter description";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handelUpdateSubmit = async (e) => {
    e.preventDefault();
    if (userCouponData?.couponId) {
      setFormData((prevData) => ({
        ...prevData,
        couponId: userCouponData.couponId,
      }));
      if (userCouponData?.couponId) {
        console.log("formData++++", formData);
        setIsLoadingOn(true);
        const tempData = await postData("coupon/updateCoupon", formData);
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
          setIsOpen(false);
          setUserCouponData(null);
          getCouponStatus(pageIndex, pageSize);
          Swal.fire({
            title: "Success!",
            icon: "success",
            text: "Coupon Updated Successfully",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: tempData?.data?.errors?.msg || "Something went wrong!!!",
          });
        }
      }
    } else if (validateForm()) {
      setIsLoadingOn(true);
      const tempData = await postData("coupon/insertCoupon", formData);
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setIsOpen(false);
        setUserCouponData(null);
        getCouponStatus(pageIndex, pageSize);
        Swal.fire({
          title: "Success!",
          icon: "success",
          text: "Coupon Added Successfully",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors?.msg || "Something went wrong!!!",
        });
      }
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const success = await getData(`coupon/deleteCoupon/${id}`);
        if (success) {
          getCouponStatus(pageIndex, pageSize);
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
        } else {
          Swal.fire("Failed!", "Your data could not be deleted.", "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const inputRef = useRef();

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    getCouponStatus(pageIndex, pageSize);
    setSelectedOptionStatus(null);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";

    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    getCouponStatus(pageIndex, pageSize);
  };

  const searchManualData = async () => {
    if (merchantRefrenceType === "Promo Code") {
      const filterData = {
        promotionalCode: searchBoxValue,
      };
      setIsLoadingOn(true);
      console.log("filterData+++",filterData)
      const tempData = await postData("coupon/getCoupon", filterData);
      setIsLoadingOn(false);

      if (tempData.statusCode === 200) {
        setCouponData(tempData?.data?.data);
      } else {
        Swal.fire({
          title: "Error!",
          text: tempData?.data?.errors?.msg || "Something went wrong!!!",
        });
      }
    } else {
      alert("Please select any one option");
    }
  };

  const getCouponStatus = async (page, pageSize) => {
    const tempSendData = {
      page: page,
      pageSize: pageSize,
    };
    setIsLoadingOn(true);
    const tempData = await postData("coupon/getCoupon", tempSendData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setCouponData(tempData?.data?.data);
      setPageCount(tempData?.data?.pagination?.totalPagesCount);
      setTotalRecords(tempData?.data?.pagination?.totalRecords);
      setErrorMessage("");
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };

  useEffect(() => {
    getCouponStatus(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const closeModal = () => {
    setIsOpen(false);
    setUserCouponData(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.index + 1 + pageIndex * pageSize}.
          </span>
        ),
      },
      {
        Header: "Promo Code",
        accessor: "promotionalCode",
      },
      {
        Header: "Discount",
        accessor: "discountPercentage",
      },
      {
        Header: "Discount On",
        accessor: "discountFor",
      },
      {
        Header: "Max Discount",
        accessor: "maxDiscount",
      },
      {
        Header: "Max Count",
        accessor: "maxCount",
      },
      {
        Header: "Coupon Start Date",
        accessor: "couponStartDate",
      },
      {
        Header: "Coupon End Date",
        accessor: "couponEndDate",
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ cell }) => {
          const statusName = cell?.row?.original?.isActive?.toUpperCase() || "";
          const statusClass =
            statusName === "YES" ? "badge-success" : "badge-danger";
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className={`badge badge-pill ${statusClass}`}>
                {statusName === "YES" ? "Active" : "Inactive"}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ cell }) => {
          const { couponId } = cell.row.original;
          return (
            <>
              <div className="loanIdDownloadBtnDiv d-flex justify-content-evenly align-items-center">
                <button
                  className="btn-sm btn-warning "
                  onClick={() => {
                    setUserCouponData(cell.row.original);
                    setIsOpen(true);
                  }}
                >
                  <span>Edit</span>
                </button>

                <button
                  className="btn-sm btn-danger"
                  onClick={() => {
                    handleDelete(couponId);
                  }}
                >
                  <span>Delete</span>
                </button>
              </div>
            </>
          );
        },
      },
    ],
    [pageIndex, pageSize]
  );

  const merchantOption = [{ value: "Promo Code", label: "Promo Code" }];

  const handleAddRow = () => {
    console.log("userCouponData?.couponId+++", userCouponData?.couponId);
    setFormData((prevData) => ({
      ...prevData,
      discountCriteria: [
        ...prevData.discountCriteria,
        {
          fieldName: "",
          operation: "",
          value: "",
          ...(userCouponData?.couponId && {
            couponId: userCouponData.couponId,
          }),
        },
      ],
    }));
  };

  const handleDeleteRow = (index) => {
    setFormData((prevData) => {
      const updatedCriteria = [...prevData.discountCriteria];
      const deletedRow = updatedCriteria[index];
      if (deletedRow.couponCriteriaId || deletedRow.couponId) {
        updatedCriteria[index] = {
          deletedCouponCriteriaId:
            deletedRow.couponCriteriaId || deletedRow.couponId,
        };
      } else {
        updatedCriteria.splice(index, 1);
      }

      console.log("updatedCriteriaLast+++", {
        ...prevData,
        discountCriteria: updatedCriteria,
      });
      return { ...prevData, discountCriteria: updatedCriteria };
    });
  };

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Coupon List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="filter"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                className="searchInputField"
                ref={inputRef}
                onChange={(e) => {
                  setSearchBoxValue(e.target.value);
                }}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            <div className="col-lg-4 text-end">
              {userRole === "Admin" || userRole === "Operation" ? (
                <button
                  className="btn btnAddCredit"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Add
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {couponData && (
          <Table
            columns={columns}
            data={couponData}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            handleGotoPage={handleGotoPage}
            errorMessage={errorMessage}
            handleSort={handleSort}
            sortBy={sortBy}
            orderBy={orderBy}
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Add Coupon</h4>
        <div className="modalUserPaymentStatusAddCoupon">
          <form onSubmit={handelUpdateSubmit}>
            <div className="row">
              <div className="col-md-12 changeProductPro">
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="discountOn" className="form-label">
                      Discount On<span>*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      name="discountFor"
                      options={[
                        { label: "Order", value: "Order" },
                        { label: "Merchant", value: "Merchant" },
                        { label: "Electrician", value: "Electrician" },
                      ]}
                      value={
                        formData.discountFor
                          ? {
                              label: formData.discountFor,
                              value: formData.discountFor,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleDropdownChange("discountFor", selectedOption)
                      }
                    />
                    {errors.discountFor && (
                      <div className="text-danger">{errors.discountFor}</div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="discountPercentage" className="form-label">
                      Discount(%)<span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Discount %"
                      className="form-control"
                      id="discountPercentage"
                      name="discountPercentage"
                      value={formData.discountPercentage}
                      onChange={handleInputChange}
                    />
                    {errors.discountPercentage && (
                      <div className="text-danger">
                        {errors.discountPercentage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="promotionalCode" className="form-label">
                      Promo Code<span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Promotional Code"
                      className="form-control"
                      id="promotionalCode"
                      name="promotionalCode"
                      value={formData.promotionalCode}
                      onChange={handleInputChange}
                    />
                    {errors.promotionalCode && (
                      <div className="text-danger">
                        {errors.promotionalCode}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="couponType" className="form-label">
                      Coupon Type<span>*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      name="couponType"
                      value={
                        formData.couponType
                          ? {
                              label: formData.couponType,
                              value: formData.couponType,
                            }
                          : null
                      }
                      options={[
                        { label: "Percentage", value: "Percentage" },
                        { label: "Amount", value: "Amount" },
                      ]}
                      onChange={(selectedOption) =>
                        handleDropdownChange("couponType", selectedOption)
                      }
                    />
                    {errors.couponType && (
                      <div className="text-danger">{errors.couponType}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="maxDiscount" className="form-label">
                      Max Discount (In Amount)<span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="₹"
                      className="form-control"
                      id="maxDiscount"
                      name="maxDiscount"
                      value={formData.maxDiscount}
                      onChange={handleInputChange}
                    />
                    {errors.maxDiscount && (
                      <div className="text-danger">{errors.maxDiscount}</div>
                    )}
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="maxCount" className="form-label">
                      Max Count<span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Max Count"
                      className="form-control"
                      id="maxCount"
                      name="maxCount"
                      value={formData.maxCount}
                      onChange={handleInputChange}
                    />
                    {errors.maxCount && (
                      <div className="text-danger">{errors.maxCount}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="couponStartDate" className="form-label">
                      Coupon Start Date<span>*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Select Date"
                      className="form-control"
                      id="couponStartDate"
                      name="couponStartDate"
                      value={formData.couponStartDate}
                      onChange={handleInputChange}
                    />
                    {errors.couponStartDate && (
                      <div className="text-danger">
                        {errors.couponStartDate}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="couponEndDate" className="form-label">
                      Coupon End Date<span>*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Select Date"
                      className="form-control"
                      id="couponEndDate"
                      name="couponEndDate"
                      value={formData.couponEndDate}
                      onChange={handleInputChange}
                    />
                    {errors.couponEndDate && (
                      <div className="text-danger">{errors.couponEndDate}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="isActive" className="form-label">
                      Coupon status<span>*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      name="isActive"
                      options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ]}
                      value={
                        formData.isActive
                          ? {
                              label: formData.isActive,
                              value: formData.isActive,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleDropdownChange("isActive", selectedOption)
                      }
                    />
                    {errors.isActive && (
                      <div className="text-danger">{errors.isActive}</div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="description" className="form-label">
                      Coupon Description<span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="description..."
                      className="form-control"
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                    {errors.description && (
                      <div className="text-danger">{errors.description}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <h6>
                    <b>Discount Criteria</b>
                  </h6>
                  {formData.discountCriteria
                    .filter((criteria) => !criteria.deletedCouponCriteriaId)
                    .map((criteria, index) => (
                      <div className="row mb-3" key={index}>
                        <div className="col-md-4">
                          <label htmlFor="fieldName" className="form-label">
                            Action<span>*</span>
                          </label>
                          <Select
                            placeholder="Select ..."
                            name="fieldName"
                            options={[
                              { label: "Number Of Orders", value: "Number Of Orders" },
                            ]}
                            value={{
                              label: criteria.fieldName || "",
                              value: criteria.fieldName || "",
                            }}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleDropdownInnerChange(
                                  index,
                                  "fieldName",
                                  selectedOption
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="operation" className="form-label">
                            Operation<span>*</span>
                          </label>
                          <Select
                            placeholder="Select ..."
                            name="operation"
                            options={[
                              { label: ">=", value: ">=" },
                              { label: "<=", value: "<=" },
                              { label: "<", value: "<" },
                              { label: ">", value: ">" },
                            ]}
                            value={{
                              label: criteria.operation || "",
                              value: criteria.operation || "",
                            }}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleDropdownInnerChange(
                                  index,
                                  "operation",
                                  selectedOption
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="value" className="form-label">
                            Value<span>*</span>
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="Value"
                              className="form-control"
                              value={criteria.value || ""}
                              onChange={(e) =>
                                handleInputChange(e, index, "value")
                              }
                            />
                            <div style={{ marginLeft: 10 }}>
                              {index > 0 ? (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleDeleteRow(index)}
                                >
                                  <MdDelete />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleAddRow}
                                >
                                  <IoAddCircleSharp />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-success text-center">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}
    </div>
  );
}

export default CouponList;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number

filterGreaterThan.autoRemove = (val) => typeof val !== "number";
