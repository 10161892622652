export const formatDateTime = (date) => {
  let tempTime = date && date?.split("T");
  // console.log(" date ", tempTime[1].split(".")[0]);
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  // let tempDate = new Date(date)
  // console.log(" d ====== ", date.split(" "));
  // console.log(" d ====== ", d);

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-") + " " + tempTime[1]?.split(".")[0];
};

export const formatDate = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
};

export const formatDateTimeSecond = (date) => {
  let tempTime = date && date?.split("T");
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/") + " " + tempTime[1]?.split(".")[0];
};

export const formatDateTimeString = (dateTimeString) => {
  if (!dateTimeString) return "";
  
  const isYearFirst = (datePart) => {
    const [firstPart] = datePart?.split("-");
    return firstPart.length === 4;
  };

  if (dateTimeString.includes(" ")) {
    let [datePart, timePart] = dateTimeString?.split(" ");
    if (isYearFirst(datePart)) {
      let [year, month, day] = datePart?.split("-");
      return `${day}/${month}/${year} ${timePart}`;
    } else {
      let [day, month, year] = datePart?.split("-");
      return `${day}/${month}/${year} ${timePart}`;
    }
  } else {
    if (isYearFirst(dateTimeString)) {
      let [year, month, day] = dateTimeString?.split("-");
      return `${day}/${month}/${year}`;
    } else {
      let [day, month, year] = dateTimeString?.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};

export const formatDateString = (dateString) => {
  if (!dateString) return "";

  let [year, month, day] = dateString?.split("-");

  return `${day}/${month}/${year}`;
};

export const formatNumberWithCommasAndDecimals = (value) => {
  const roundedValue = Math.round(value * 100) / 100;
  const fixedValue = roundedValue.toFixed(2);

  // Split the integer and decimal parts
  const [integerPart, decimalPart] = fixedValue?.split('.');

  // Format the integer part according to the Indian numbering system
  let lastThreeDigits = integerPart?.slice(-3);
  let otherDigits = integerPart?.slice(0, -3);

  if (otherDigits !== '') {
    otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    lastThreeDigits = `,${lastThreeDigits}`;
  }

  const formattedIntegerPart = otherDigits + lastThreeDigits;

  return `${formattedIntegerPart}.${decimalPart}`;
};
