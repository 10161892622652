import React, { useState } from 'react';
import './Login.css';
import { useHistory } from 'react-router-dom';

function LoginPage({ handleLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(username, password, history);
  };

  return (
    <div className='loginBodyPage'>
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <h1>Login</h1>
          <div className="input-box">
            <input type="text" placeholder="Username" required=""
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <i className="bx bxs-user" />
          </div>
          <div className="input-box">
            <input type="password" placeholder="password" required=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i className="bx bxs-lock-alt" />
          </div>
          <div style={{textAlign:"right"}}>
            <button type="button" onClick={()=>{history.push("/ForgetPassword");}} className="forgotpassword">
              Forget Password?
            </button>
          </div>
          <button type="submit" className="btn">
            Login
          </button>
        </form>
      </div>
    </div>

  );
}

export default LoginPage;