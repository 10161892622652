import React, { useEffect, useState } from 'react';
import '../css/makeTable.css'
import { getData } from '../store/utils';
import Loader from '../component/Loader';
import { formatDateTimeSecond } from '../component/common';

function LoanDetails() {
    const [userLoanDataInfo, setUserLoanDataInfo] = useState([]);
    const [isLoadingOn, setIsLoadingOn] = useState(false);

    console.log("userLoanDataInfo----", userLoanDataInfo)

    const getDataLoanDetails = async () => {
        setIsLoadingOn(true);
        // if (loanId) {
        const tempData = await getData("loan/getLoanDetails/1");
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setUserLoanDataInfo(tempData.data[0]);
        }
        // }

    };
    useEffect(() => {
        getDataLoanDetails();
    }, [])

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}
            <div className='merchantDetailsHeading'>
                <h4 style={{ fontWeight: "700" }}>Loan Details</h4>
            </div>
            <div className="merchantdesignlay">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>₹ {userLoanDataInfo && userLoanDataInfo.amount}</h5>
                                <p>Product Value</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.tenure}</h5>
                                <p>No Of Instalment Months</p>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.rateOfInterest}</h5>
                                <p>Interest</p>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.firstEmiDate ? formatDateTimeSecond(userLoanDataInfo.firstEmiDate): ""}</h5>
                                <p>First EMI Date</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Processing Fee</p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    {/* -------------------------------------------------------------------------------------- */}
                    <div className="row">
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Total payment</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.amount}</h5>
                                <p>Loan Amount</p>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.emiAmount}</h5>
                                <p>Instalment Amount</p>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Interest Amount</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Bullet Loan Days</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {/* -------------------------------------------------------------------------------------- */}
                    <div className="row">
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Down Payment Amount</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Interest Component</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.totalAmountPaid}</h5>
                                <p>Total Principal Paid</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.paidInterest ? (userLoanDataInfo.paidInterest).toFixed(2): ""}</h5>
                                <p>Total Interest Paid</p>
                            </div>
                        </div><div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.paidEMICount}</h5>
                                <p>No of Emi Paid</p>
                            </div>
                        </div><div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Current DPD</p>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>Na/Na</h5>
                                <p>Cooling Off Date</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.totalAmountNotPaid}</h5>
                                <p>Total Principal Pending</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.notPaidInterest}</h5>
                                <p>Total Interest Pending</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>{userLoanDataInfo && userLoanDataInfo.notPaidEMICount}</h5>
                                <p>No of EMI Pending</p>
                            </div>
                        </div><div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Max DPD</p>
                            </div>
                        </div><div className="col-md-2">
                            <div className='merchantNameDetails'>
                                <h5>-</h5>
                                <p>Loan Closing Date</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LoanDetails;