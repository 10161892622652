import React, { useState, useMemo, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import Table from "../pages/ReactTable"
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData, postData } from "../store/utils";
import { formatDate, formatDateTimeSecond, formatDateString } from "../component/common";
import LeaveRequestActionModal from "./LeaveRequestActionModal";

const LeaveRequests = () => {
  const inputRef = useRef();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [records, setRecords] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [queryParams, setQueryParams] = useState("");
  const [leaveRequestActionModalIsOpen, setLeaveRequestActionModalIsOpen] = useState(false);
  const [viewingLeaveRequestData, setViewingLeaveRequestData] = useState({});

  const getLeaveRequestsData = async (
    page = pageIndex,
    pageSize = pageSize,
    sortBy = sortBy,
    orderBy = orderBy,
    queryParams = queryParams
  ) => {
    if (isLoadingOn) return;
    
    setIsLoadingOn(true);
    let apiUrl = `leave-request?page=${page + 1}&pageSize=${pageSize}`;
    if (sortBy && orderBy) {
      apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }
    if (queryParams) {
      apiUrl += `&${queryParams}`;
    }

    try {
      const getDataResult = await getData(apiUrl);
      if (getDataResult.statusCode == 200) {
        setRecords(getDataResult.data.records);
        setPageCount(getDataResult.data.pagination.totalPagesCount);
        setTotalRecords(getDataResult.data.pagination.totalRecords);

        if (getDataResult?.data?.pagination?.totalRecords == 0) {
          setErrorMessage("No data found");
        } else {
          setErrorMessage("");
        }
      }
      else {
        Swal.fire({
          title: "Error!",
          text: getDataResult?.response?.data?.errors?.msg || "Something went wrong."
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while fetching data."
      });
    } finally {
      setIsLoadingOn(false);
    }
  };

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    if (column.disableSortBy) return;
    let newOrderBy = "asc";
  
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
  };
  
  useEffect(() => {
    getLeaveRequestsData(pageIndex, pageSize, sortBy, orderBy, queryParams);
  }, [pageIndex, pageSize, sortBy, orderBy, queryParams]);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
        disableSortBy: true,
      },
      {
        Header: "Employee Name",
        accessor: "employeeName",
      },
      {
        Header: "From Date",
        accessor: "fromDate",
        Cell: ({ cell }) => (
          <span>{cell?.row?.original?.fromDate ? formatDateString(cell.row.original.fromDate) : ''}</span>
        ),
      },
      {
        Header: "To Date",
        accessor: "toDate",
        Cell: ({ cell }) => (
          <span>{cell?.row?.original?.toDate ? formatDateString(cell.row.original.toDate) : ''}</span>
        ),
      },
      {
        Header: "Approver Name",
        accessor: "approverName",
      },
      {
        Header: "Approver Action Timestamp",
        accessor: "approverActionTimestamp",
        Cell: ({ cell }) => (
          <span>{cell?.row?.original?.approverActionTimestamp ? formatDateTimeSecond(cell.row.original.approverActionTimestamp) : ''}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "statusName",
        Cell: ({ cell }) => {
          const statusName = cell?.row?.original?.statusName?.toUpperCase() || '';
          const statusClass = statusName === "APPROVED"
            ? "badge-success"
            : statusName === "REJECT"
              ? "badge-danger"
              : "badge-warning";
          
          return (
            <div
              style={{ display: "flex", justifyContent: "center" }}
            >
              <span
                className={`badge badge-pill ${statusClass}`}
              >
                {cell.row.original.statusName}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv text-center">
                <button
                  className={`btn btn-outline-success`}
                  onClick={() => handleOpenLeaveRequestActionModal(cell.row.original)}
                >
                  <span style={{ fontWeight: "600" }}>View</span>
                </button>
              </div>
            </>
          );
        },
      },
    ],
    [pageIndex, pageSize]
  );

  const searchManualData = async () => {
    let filterData = {};

    if (searchBoxValue) {
      filterData = {
        employeeName: searchBoxValue,
      };
    } else {
      alert("Please enter employee name to search.");
      return;
    }

    setPageIndex(0);
    const tempQueryParams = new URLSearchParams(filterData).toString();
    setQueryParams(tempQueryParams);
  };

  const resetFilters = () => {
    inputRef.current.value = "";
    setSearchBoxValue("");
    setQueryParams("");
    setPageIndex(0);
  };

  const handleOpenLeaveRequestActionModal = (leaveRequest) => {
    setLeaveRequestActionModalIsOpen(true);
    setViewingLeaveRequestData(leaveRequest);
  };
  
  const handleCloseLeaveRequestActionModal = () => {
    setLeaveRequestActionModalIsOpen(false);
    setViewingLeaveRequestData({});
  };

  const handleLeaveRequestActionSubmit = async (data) => {
    if (!data.status) {
      Swal.fire({
        title: "Error!",
        text: "Please select a status."
      });
    } else if (!data.leaveRequestId) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong."
      });
    }

    try {
      setIsLoadingOn(true);
      const postDataResult = await postData("leave-request/update-status", data);

      if (postDataResult.statusCode == 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: postDataResult.message || "Leave request updated successfully!",
        });
        getLeaveRequestsData(pageIndex, pageSize, sortBy, orderBy, queryParams);
        handleCloseLeaveRequestActionModal();
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: postDataResult?.data?.errors?.msg || "Something went wrong to update leave request.",
        });
      }
    } catch (error) {
      console.log("handleLeaveRequestActionSubmit ~ error:", error);
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "Something went wrong to update leave request.",
      });
    } finally {
      setIsLoadingOn(false);
    }
  }

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Leave Requests</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-3">
              <input
                type="text"
                className="searchInputField w-100"
                ref={inputRef}
                onChange={(e) => setSearchBoxValue(e.target.value)}
                placeholder="Enter employee name"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2">
              <button
                type="button"
                className="searchTableData"
                onClick={searchManualData}
              >
                <FaSearch /> Search
              </button>
            </div>
            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {records && <Table 
          columns={columns} 
          data={records} 
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          handleGotoPage={handleGotoPage}
          errorMessage={errorMessage} 
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />}
      </div>

      <LeaveRequestActionModal
        modalIsOpen={leaveRequestActionModalIsOpen}
        closeModal={handleCloseLeaveRequestActionModal}
        leaveRequestData={viewingLeaveRequestData}
        handelSubmit={handleLeaveRequestActionSubmit}
      />
    </>
  );
};

export default LeaveRequests;