import React, { useState, useMemo, useRef, useEffect } from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData, postData } from "../store/utils";
import Swal from "sweetalert2";
import { HiRefresh } from "react-icons/hi";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Table from "../pages/ReactTable"
import { formatDateTimeSecond } from '../component/common.js';

const LeadDistributorList = () => {
  const [leadDistributorData, setLeadDistributorData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const inputRef = useRef();
  const history = useHistory();
  const getLeadDistributorList = async (page = 0, pageSize = 10) => {
    const filterData = {
      page: page + 1,
      pageSize: pageSize
  }
    setIsLoadingOn(true);
    const tempData = await postData(`lead/getLeads`, filterData);
    setIsLoadingOn(false);
    if (tempData?.statusCode == 200) {
      const distributorData = tempData?.data?.records || []
      setLeadDistributorData(distributorData);
      setPageCount(tempData?.data?.pagination?.totalPagesCount);
      setTotalRecords(tempData?.data?.pagination?.totalRecords);
    }
    else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors?.msg || "Something went wrong", 
      })
    }
  };

  const displayDistributorInfo = (leadId) => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/LeadDistributorDetails?leadId=" + leadId;
    } else {
      history.push({
        pathname: "/LeadDistributorDetails",
        state: { leadId: leadId },
      });
    }
  };

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    getLeadDistributorList(pageIndex, pageSize, column.id, newOrderBy);
  };

  useEffect(() => {
    getLeadDistributorList(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "Id",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell?.row?.original?.id}
                  onClick={() => displayDistributorInfo(cell?.row?.original?.id)}
                >
                  <span style={{ fontWeight: "600" }}>
                    {"L-" + cell?.row?.original?.id}
                  </span>
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: false,
      },
      {
        Header: "Business Name",
        accessor: "businessName",
      },
      {
        Header: "Owner Name",
        accessor: "ownerName",
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
      },
      
      {
        Header: "Shop Name",
        accessor: "shopName",
      },
      {
        Header: "Merchant Mobile",
        accessor: "merchantMobile",
      },
      {
        Header: "City",
        accessor: "city",
      },{
        Header: "Branch",
        accessor: "branch",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Lead Created At",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (
            <>
              <div>
                {formatDateTimeSecond(cell?.row?.original?.createdAt)}
              </div>
            </>
          );
        },
      },
    ],
    [pageIndex, pageSize]
  );

  const merchantOption = [
    { value: "leadMobileNumber", label: "Mobile Number" },
    { value: "leadOwnerName", label: "Owner Name" },
    { value: "LeadBusinessName", label: "Business Name" }
  ];
  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    getLeadDistributorList();
    setSelectedOptionStatus(null);
  };
  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "leadMobileNumber") {
      filterData = {
        leadMobileNumber: searchBoxValue,
      };
    } if (merchantRefrenceType === "leadOwnerName") {
      filterData = {
        leadOwnerName: searchBoxValue,
      };
    } if (merchantRefrenceType === "LeadBusinessName") {
      filterData = {
        LeadBusinessName: searchBoxValue,
      };
    }
    else {
      // alert("Please select any one option");
    }

    const filterDataPayload = {
      page : 1,
      pageSize : 10,
      leadMobileNumber : filterData?.leadMobileNumber,
      leadOwnerName : filterData?.leadOwnerName,
      LeadBusinessName : filterData?.LeadBusinessName,
      businessType : "3",
    }

    setIsLoadingOn(true);
    const tempData = await postData(`lead/getLeads`, filterDataPayload)

    setIsLoadingOn(false);

    if (tempData?.statusCode === 200) {
      // setLeadDistributorData(tempData?.data?.records);
      const distributorData = tempData?.data?.records || []
      setLeadDistributorData(distributorData);
      setPageCount(tempData?.data?.pagination?.totalPagesCount);
      setTotalRecords(tempData?.data?.pagination?.totalRecords);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors?.msg || "Something went wrong",
      });
    }
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Lead Distributor List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-3">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-3">
              <input
                type={merchantRefrenceType === "Mobile Number" ? "tel" : "text"}
                className="searchInputField"
                ref={inputRef}
                onChange={(e) => {
                  setSearchBoxValue(e.target.value);
                }}
                // onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {leadDistributorData ? (
          <Table
            columns={columns}
            data={leadDistributorData}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            handleGotoPage={handleGotoPage}
            errorMessage={errorMessage}
            handleSort={handleSort}
            sortBy={sortBy}
            orderBy={orderBy}
          />
        ) : (
          <div style={{fontWeight:"bold"}}>No data available</div> // Render a fallback message
        )}
      </div>
    </>
  )
}

export default LeadDistributorList