import React from 'react'
import Routes from './component/Routes';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './css/style.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Routes />
      <ToastContainer
        position="top-right"
        autoClose={10000}
        theme="colored"
      />
    </div>
  );
}

export default App;
