const ApiResponseDummy= [
    [    
        {
            "section": 1001,
            "setId": 1,
            "setValue": "Description",
            "sectionName": "Bank Accounts",
            "type": "STRING",
            "sectionValues": [
                {
                    "id": 4,
                    "key": "Account Holders",
                    "value": "Account Holders",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 3,
                    "key": "Account Number",
                    "value": "Account Number",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 1,
                    "key": "Bank Name",
                    "value": "Bank Name",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 2,
                    "key": "Account Type",
                    "value": "Account Type",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 5,
                    "key": "Email",
                    "value": "Email",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 6,
                    "key": "Phone Number",
                    "value": "Phone Number",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 7,
                    "key": "Statement From",
                    "value": "Statement From",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 8,
                    "key": "Statement To",
                    "value": "Statement To",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 85,
                    "key": "Txn Start Date",
                    "value": "Txn Start Date",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 86,
                    "key": "Txn End Date",
                    "value": "Txn End Date",
                    "style": "REGULAR",
                    "type": "STRING"
                }
            ]
        },
        {
            "section": 1001,
            "setId": 2,
            "setValue": "AXISIN - 123456763542 - Current",
            "sectionName": "Bank Accounts",
            "type": "STRING",
            "sectionValues": [
                {
                    "id": 4,
                    "key": "Account Holders",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 3,
                    "key": "Account Number",
                    "value": "1234567890",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 1,
                    "key": "Bank Name",
                    "value": "Axis Bank",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 2,
                    "key": "Account Type",
                    "value": "Current",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 5,
                    "key": "Email",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 6,
                    "key": "Phone Number",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 7,
                    "key": "Statement From",
                    "value": "02/01/2020",
                    "style": "REGULAR",
                    "type": "DATE"
                },
                {
                    "id": 8,
                    "key": "Statement To",
                    "value": "02/09/2020",
                    "style": "REGULAR",
                    "type": "DATE"
                },
                {
                    "id": 85,
                    "key": "Txn Start Date",
                    "value": "05/01/2020",
                    "style": "REGULAR",
                    "type": "DATE"
                },
                {
                    "id": 86,
                    "key": "Txn End Date",
                    "value": "25/08/2020",
                    "style": "REGULAR",
                    "type": "DATE"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 1,
            "setValue": "Description",
            "sectionName": "Monthwise details",
            "type": "STRING",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "Opening Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "Debit Txns",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "Credit Txns",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "Closing Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "I/W Cheque Bounces: Insufficient Funds Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "FOIR Score",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "Fixed Obligations aka Loan Repayments",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "Salary Income",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "O/W Cheque Bounces",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "I/W Cheque Bounces %",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "Minimum Balance Charges",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "Debit Txns Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "Credit Txns Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "Min Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "Max Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "Monthly Average Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "Cash Deposit",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "Cash Withdrawal",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "Loan Credit",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "Cash Deposits %",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "Cash Deposits Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "Cash Withdrawals Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "Cheque Deposits Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "Cheque Deposits",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "Cheque Issues Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "Cheque Issues",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "Internal Debit Txns Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "Internal Credit Txns Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "Internal Debit Txns",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "Internal Credit Txns",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "Peak Utilization Limit",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "Net Debit",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "Net Credit",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "Interest Paid",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "Interest Received",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "Min EOD Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "Max EOD Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "Average EOD Balance",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "Self Withdrawal",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "Self Deposit",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "Net Debit Txns Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "Net Credit Txns Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "OD/CC Avg Utilization %",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "Overdrawn Days",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "NEFT Returns",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "Sanction Limit",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "Balance on 1st",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "Balance on 14th",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "Balance on 30th/Last Day",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "ABB on 1st,14th, 30th/Last Day",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "Daily Balance Change %",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "ECS/NACH Issued Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "ECS/NACH Issued",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "EMI/ Loan Payments Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "EMI/ Loan Payments",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "Penalty Charges Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "Penalty Charges",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "Interest Service Delay",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "Bank Charges Count",
                    "style": "REGULAR",
                    "type": "STRING"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "Bank Charges",
                    "style": "REGULAR",
                    "type": "STRING"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 2,
            "setValue": "Jan 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "12345.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "168377.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "2563.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "477082.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "477082.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "487505.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "480995.11",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "2563.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "2563.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "168377.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "2563.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "477082.04000000004",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "487505.04000000004",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "480995.11407407414",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "484505.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "477082.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "320529.03",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "7423.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 3,
            "setValue": "Feb 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "477082.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "857706.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "57919.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "-322704.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "1176814.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "18",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "-322704.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "343708.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "-223075.48",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "9827.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "267082.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "57919.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "-322704.95999999996",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "343708.04000000004",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "-223075.47724137927",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "5",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "343708.04",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "-314114.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "-322704.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "-97703.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "-1.07%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "11",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "595830.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "10",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "588407.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "2226.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "2226.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 4,
            "setValue": "Mar 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "-322704.95999999996",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "629508.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "130374.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "-821838.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "8",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "-861403.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "-322704.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "-764418.31",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "130374.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "629508.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "130374.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "-861403.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "-322704.95999999996",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "-764418.3148387097",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "8",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "-322704.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "-840980.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "-821838.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "-661841.63",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "0.39%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "7423.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "11375.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "11375.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 5,
            "setValue": "Apr 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "-821838.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "796742.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "995027.83",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "-623553.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "122208.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "16.67%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "9",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "6",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "-739368.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "-612808.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "-641887.42",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "150000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "15.07%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "650000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "705279.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "730423.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "813027.83",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "-739368.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "-612808.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "-641887.4236666666",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "-680558.96",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "-634090.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "-623553.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "-646067.41",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "1.09%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "10298.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "58154.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "2265.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "2265.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 6,
            "setValue": "May 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "-623553.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "83949.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "521580.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "-185922.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "5500.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "-694579.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "-185922.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "-437302.36",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "165000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "3",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "356580.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "5500.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "83949.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "356580.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "-694579.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "-185922.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "-437302.3558064515",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "4",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "3",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "-623553.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "-529579.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "-185922.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "-446351.46",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "3.35%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "7423.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 7,
            "setValue": "Jun 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "-185922.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "100818.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "19274.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "-267466.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "96790.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "38548.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "5",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "-267466.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "-185922.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "-221624.5",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "19274.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "19274.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "52423.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "19274.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "-267466.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "-185922.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "-221624.49666666656",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "3",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "-185922.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "-190922.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "-267466.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "-214770.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "0.7%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "36544.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "48395.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 8,
            "setValue": "Jul 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "-267466.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "208173.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "750000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "274360.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "207186.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "5",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "-299370.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "274360.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "-204442.68",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "600000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "104580.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "150000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "-299370.1299999999",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "274360.8700000001",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "-204442.67838709668",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "3",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "-267466.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "-117466.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "274360.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "-36857.13",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "-0.97%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "3",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "111016.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "103593.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "26269.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "26269.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 9,
            "setValue": "Aug 2020",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "274360.8700000001",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "36544.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "19660.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "257476.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "58242.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "257476.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "294020.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "283595.63",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "0.0%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "7423.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "19660.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "257476.8700000001",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "294020.8700000001",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "283595.6300000001",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "274360.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "294020.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "257476.87",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "275286.2",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "1.07%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "2",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "36544.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "1",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "29121.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        },
        {
            "section": 1002,
            "setId": 10,
            "setValue": "Total",
            "sectionName": "Monthwise details",
            "type": "COUNT",
            "sectionValues": [
                {
                    "id": 9,
                    "key": "Opening Balance",
                    "value": "-103393.29",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 10,
                    "key": "Debit Txns",
                    "value": "2881817.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 11,
                    "key": "Credit Txns",
                    "value": "2496397.83",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 12,
                    "key": "Closing Balance",
                    "value": "-151570.69",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 13,
                    "key": "I/W Cheque Bounces: Insufficient Funds Count",
                    "value": "1.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 97,
                    "key": "FOIR Score",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 98,
                    "key": "Fixed Obligations aka Loan Repayments",
                    "value": "1661240.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 99,
                    "key": "Salary Income",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 14,
                    "key": "O/W Cheque Bounces",
                    "value": "3.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 15,
                    "key": "I/W Cheque Bounces %",
                    "value": "16.67%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 16,
                    "key": "Minimum Balance Charges",
                    "value": "44048.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 17,
                    "key": "Debit Txns Count",
                    "value": "55.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 18,
                    "key": "Credit Txns Count",
                    "value": "19.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 19,
                    "key": "Min Balance",
                    "value": "-306291.79",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 20,
                    "key": "Max Balance",
                    "value": "11529.68",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 21,
                    "key": "Monthly Average Balance",
                    "value": "-216020.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 22,
                    "key": "Cash Deposit",
                    "value": "150000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 23,
                    "key": "Cash Withdrawal",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 24,
                    "key": "Loan Credit",
                    "value": "765000.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 25,
                    "key": "Cash Deposits %",
                    "value": "15.07%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 26,
                    "key": "Cash Deposits Count",
                    "value": "1.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 27,
                    "key": "Cash Withdrawals Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 28,
                    "key": "Cheque Deposits Count",
                    "value": "8.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 29,
                    "key": "Cheque Deposits",
                    "value": "1158791.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 30,
                    "key": "Cheque Issues Count",
                    "value": "7.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 31,
                    "key": "Cheque Issues",
                    "value": "742443.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 32,
                    "key": "Internal Debit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 33,
                    "key": "Internal Credit Txns Count",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 34,
                    "key": "Internal Debit Txns",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 35,
                    "key": "Internal Credit Txns",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 36,
                    "key": "Peak Utilization Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 37,
                    "key": "Net Debit",
                    "value": "2043765.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 38,
                    "key": "Net Credit",
                    "value": "1549397.83",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 39,
                    "key": "Interest Paid",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 40,
                    "key": "Interest Received",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 41,
                    "key": "Min EOD Balance",
                    "value": "-306291.79",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 42,
                    "key": "Max EOD Balance",
                    "value": "11529.68",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 43,
                    "key": "Average EOD Balance",
                    "value": "-216020.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 44,
                    "key": "Self Withdrawal",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 45,
                    "key": "Self Deposit",
                    "value": "0.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 46,
                    "key": "Net Debit Txns Count",
                    "value": "32.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 47,
                    "key": "Net Credit Txns Count",
                    "value": "15.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 48,
                    "key": "OD/CC Avg Utilization %",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 49,
                    "key": "Overdrawn Days",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 50,
                    "key": "NEFT Returns",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 51,
                    "key": "Sanction Limit",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 52,
                    "key": "Balance on 1st",
                    "value": "-182767.05",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 53,
                    "key": "Balance on 14th",
                    "value": "-231078.44",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 54,
                    "key": "Balance on 30th/Last Day",
                    "value": "-151570.69",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 55,
                    "key": "ABB on 1st,14th, 30th/Last Day",
                    "value": "-188472.06",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 56,
                    "key": "Daily Balance Change %",
                    "value": "0.57%",
                    "style": "REGULAR",
                    "type": "PERCENTAGE"
                },
                {
                    "id": 57,
                    "key": "ECS/NACH Issued Count",
                    "value": "23.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 58,
                    "key": "ECS/NACH Issued",
                    "value": "812501.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 59,
                    "key": "EMI/ Loan Payments Count",
                    "value": "17.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 60,
                    "key": "EMI/ Loan Payments",
                    "value": "827670.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 61,
                    "key": "Penalty Charges Count",
                    "value": "8.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 62,
                    "key": "Penalty Charges",
                    "value": "42135.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 63,
                    "key": "Interest Service Delay",
                    "value": "-",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                },
                {
                    "id": 64,
                    "key": "Bank Charges Count",
                    "value": "8.0",
                    "style": "REGULAR",
                    "type": "COUNT"
                },
                {
                    "id": 65,
                    "key": "Bank Charges",
                    "value": "42135.0",
                    "style": "REGULAR",
                    "type": "AMOUNT"
                }
            ]
        }
    ],
    [

    {
        "section": 1001,
        "setId": 1,
        "setValue": "Description",
        "sectionName": "Bank Accounts",
        "type": "STRING",
        "sectionValues": [
            {
                "id": 4,
                "key": "Account Holders",
                "value": "Account Holders",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 3,
                "key": "Account Number",
                "value": "Account Number",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 1,
                "key": "Bank Name",
                "value": "Bank Name",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 2,
                "key": "Account Type",
                "value": "Account Type",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 5,
                "key": "Email",
                "value": "Email",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 6,
                "key": "Phone Number",
                "value": "Phone Number",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 7,
                "key": "Statement From",
                "value": "Statement From",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 8,
                "key": "Statement To",
                "value": "Statement To",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 85,
                "key": "Txn Start Date",
                "value": "Txn Start Date",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 86,
                "key": "Txn End Date",
                "value": "Txn End Date",
                "style": "REGULAR",
                "type": "STRING"
            }
        ]
    },
    {
        "section": 1001,
        "setId": 2,
        "setValue": "AXISIN - 123456763542 - Current",
        "sectionName": "Bank Accounts",
        "type": "STRING",
        "sectionValues": [
            {
                "id": 4,
                "key": "Account Holders",
                "value": "-",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 3,
                "key": "Account Number",
                "value": "0987654321",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 1,
                "key": "Bank Name",
                "value": "Axis Bank",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 2,
                "key": "Account Type",
                "value": "Current",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 5,
                "key": "Email",
                "value": "-",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 6,
                "key": "Phone Number",
                "value": "-",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 7,
                "key": "Statement From",
                "value": "02/01/2020",
                "style": "REGULAR",
                "type": "DATE"
            },
            {
                "id": 8,
                "key": "Statement To",
                "value": "02/09/2020",
                "style": "REGULAR",
                "type": "DATE"
            },
            {
                "id": 85,
                "key": "Txn Start Date",
                "value": "05/01/2020",
                "style": "REGULAR",
                "type": "DATE"
            },
            {
                "id": 86,
                "key": "Txn End Date",
                "value": "25/08/2020",
                "style": "REGULAR",
                "type": "DATE"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 1,
        "setValue": "Description",
        "sectionName": "Monthwise details",
        "type": "STRING",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "Opening Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "Debit Txns",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "Credit Txns",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "Closing Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "I/W Cheque Bounces: Insufficient Funds Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "FOIR Score",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "Fixed Obligations aka Loan Repayments",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "Salary Income",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "O/W Cheque Bounces",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "I/W Cheque Bounces %",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "Minimum Balance Charges",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "Debit Txns Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "Credit Txns Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "Min Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "Max Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "Monthly Average Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "Cash Deposit",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "Cash Withdrawal",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "Loan Credit",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "Cash Deposits %",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "Cash Deposits Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "Cash Withdrawals Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "Cheque Deposits Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "Cheque Deposits",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "Cheque Issues Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "Cheque Issues",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "Internal Debit Txns Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "Internal Credit Txns Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "Internal Debit Txns",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "Internal Credit Txns",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "Peak Utilization Limit",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "Net Debit",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "Net Credit",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "Interest Paid",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "Interest Received",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "Min EOD Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "Max EOD Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "Average EOD Balance",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "Self Withdrawal",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "Self Deposit",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "Net Debit Txns Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "Net Credit Txns Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "OD/CC Avg Utilization %",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "Overdrawn Days",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "NEFT Returns",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "Sanction Limit",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "Balance on 1st",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "Balance on 14th",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "Balance on 30th/Last Day",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "ABB on 1st,14th, 30th/Last Day",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "Daily Balance Change %",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "ECS/NACH Issued Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "ECS/NACH Issued",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "EMI/ Loan Payments Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "EMI/ Loan Payments",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "Penalty Charges Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "Penalty Charges",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "Interest Service Delay",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "Bank Charges Count",
                "style": "REGULAR",
                "type": "STRING"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "Bank Charges",
                "style": "REGULAR",
                "type": "STRING"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 2,
        "setValue": "Jan 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "54321.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "168377.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "2563.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "477082.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "477082.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "487505.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "480995.11",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "2563.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "2563.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "168377.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "2563.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "477082.04000000004",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "487505.04000000004",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "480995.11407407414",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "484505.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "477082.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "320529.03",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "7423.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 3,
        "setValue": "Feb 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "477082.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "857706.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "57919.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "-322704.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "1176814.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "18",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "-322704.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "343708.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "-223075.48",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "9827.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "267082.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "57919.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "-322704.95999999996",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "343708.04000000004",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "-223075.47724137927",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "5",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "343708.04",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "-314114.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "-322704.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "-97703.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "-1.07%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "11",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "595830.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "10",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "588407.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "2226.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "2226.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 4,
        "setValue": "Mar 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "-322704.95999999996",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "629508.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "130374.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "-821838.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "8",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "-861403.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "-322704.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "-764418.31",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "130374.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "629508.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "130374.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "-861403.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "-322704.95999999996",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "-764418.3148387097",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "8",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "-322704.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "-840980.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "-821838.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "-661841.63",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "0.39%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "7423.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "11375.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "11375.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 5,
        "setValue": "Apr 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "-821838.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "796742.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "995027.83",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "-623553.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "122208.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "16.67%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "9",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "6",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "-739368.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "-612808.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "-641887.42",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "150000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "15.07%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "650000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "705279.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "730423.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "813027.83",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "-739368.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "-612808.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "-641887.4236666666",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "-680558.96",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "-634090.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "-623553.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "-646067.41",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "1.09%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "10298.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "58154.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "2265.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "2265.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 6,
        "setValue": "May 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "-623553.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "83949.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "521580.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "-185922.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "5500.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "-694579.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "-185922.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "-437302.36",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "165000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "3",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "356580.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "5500.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "83949.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "356580.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "-694579.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "-185922.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "-437302.3558064515",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "4",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "3",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "-623553.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "-529579.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "-185922.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "-446351.46",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "3.35%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "7423.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 7,
        "setValue": "Jun 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "-185922.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "100818.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "19274.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "-267466.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "96790.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "38548.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "5",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "-267466.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "-185922.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "-221624.5",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "19274.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "19274.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "52423.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "19274.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "-267466.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "-185922.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "-221624.49666666656",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "3",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "-185922.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "-190922.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "-267466.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "-214770.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "0.7%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "36544.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "48395.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 8,
        "setValue": "Jul 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "-267466.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "208173.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "750000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "274360.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "207186.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "5",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "-299370.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "274360.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "-204442.68",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "600000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "104580.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "150000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "-299370.1299999999",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "274360.8700000001",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "-204442.67838709668",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "3",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "-267466.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "-117466.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "274360.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "-36857.13",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "-0.97%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "3",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "111016.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "103593.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "26269.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "26269.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 9,
        "setValue": "Aug 2020",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "274360.8700000001",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "36544.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "19660.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "257476.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "58242.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "257476.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "294020.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "283595.63",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "0.0%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "7423.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "19660.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "257476.8700000001",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "294020.8700000001",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "283595.6300000001",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "274360.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "294020.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "257476.87",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "275286.2",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "1.07%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "2",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "36544.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "1",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "29121.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    },
    {
        "section": 1002,
        "setId": 10,
        "setValue": "Total",
        "sectionName": "Monthwise details",
        "type": "COUNT",
        "sectionValues": [
            {
                "id": 9,
                "key": "Opening Balance",
                "value": "-103393.29",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 10,
                "key": "Debit Txns",
                "value": "2881817.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 11,
                "key": "Credit Txns",
                "value": "2496397.83",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 12,
                "key": "Closing Balance",
                "value": "-151570.69",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 13,
                "key": "I/W Cheque Bounces: Insufficient Funds Count",
                "value": "1.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 97,
                "key": "FOIR Score",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 98,
                "key": "Fixed Obligations aka Loan Repayments",
                "value": "1661240.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 99,
                "key": "Salary Income",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 14,
                "key": "O/W Cheque Bounces",
                "value": "3.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 15,
                "key": "I/W Cheque Bounces %",
                "value": "16.67%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 16,
                "key": "Minimum Balance Charges",
                "value": "44048.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 17,
                "key": "Debit Txns Count",
                "value": "55.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 18,
                "key": "Credit Txns Count",
                "value": "19.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 19,
                "key": "Min Balance",
                "value": "-306291.79",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 20,
                "key": "Max Balance",
                "value": "11529.68",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 21,
                "key": "Monthly Average Balance",
                "value": "-216020.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 22,
                "key": "Cash Deposit",
                "value": "150000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 23,
                "key": "Cash Withdrawal",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 24,
                "key": "Loan Credit",
                "value": "765000.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 25,
                "key": "Cash Deposits %",
                "value": "15.07%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 26,
                "key": "Cash Deposits Count",
                "value": "1.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 27,
                "key": "Cash Withdrawals Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 28,
                "key": "Cheque Deposits Count",
                "value": "8.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 29,
                "key": "Cheque Deposits",
                "value": "1158791.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 30,
                "key": "Cheque Issues Count",
                "value": "7.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 31,
                "key": "Cheque Issues",
                "value": "742443.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 32,
                "key": "Internal Debit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 33,
                "key": "Internal Credit Txns Count",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 34,
                "key": "Internal Debit Txns",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 35,
                "key": "Internal Credit Txns",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 36,
                "key": "Peak Utilization Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 37,
                "key": "Net Debit",
                "value": "2043765.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 38,
                "key": "Net Credit",
                "value": "1549397.83",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 39,
                "key": "Interest Paid",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 40,
                "key": "Interest Received",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 41,
                "key": "Min EOD Balance",
                "value": "-306291.79",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 42,
                "key": "Max EOD Balance",
                "value": "11529.68",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 43,
                "key": "Average EOD Balance",
                "value": "-216020.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 44,
                "key": "Self Withdrawal",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 45,
                "key": "Self Deposit",
                "value": "0.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 46,
                "key": "Net Debit Txns Count",
                "value": "32.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 47,
                "key": "Net Credit Txns Count",
                "value": "15.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 48,
                "key": "OD/CC Avg Utilization %",
                "value": "-",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 49,
                "key": "Overdrawn Days",
                "value": "-",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 50,
                "key": "NEFT Returns",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 51,
                "key": "Sanction Limit",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 52,
                "key": "Balance on 1st",
                "value": "-182767.05",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 53,
                "key": "Balance on 14th",
                "value": "-231078.44",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 54,
                "key": "Balance on 30th/Last Day",
                "value": "-151570.69",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 55,
                "key": "ABB on 1st,14th, 30th/Last Day",
                "value": "-188472.06",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 56,
                "key": "Daily Balance Change %",
                "value": "0.57%",
                "style": "REGULAR",
                "type": "PERCENTAGE"
            },
            {
                "id": 57,
                "key": "ECS/NACH Issued Count",
                "value": "23.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 58,
                "key": "ECS/NACH Issued",
                "value": "812501.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 59,
                "key": "EMI/ Loan Payments Count",
                "value": "17.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 60,
                "key": "EMI/ Loan Payments",
                "value": "827670.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 61,
                "key": "Penalty Charges Count",
                "value": "8.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 62,
                "key": "Penalty Charges",
                "value": "42135.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 63,
                "key": "Interest Service Delay",
                "value": "-",
                "style": "REGULAR",
                "type": "AMOUNT"
            },
            {
                "id": 64,
                "key": "Bank Charges Count",
                "value": "8.0",
                "style": "REGULAR",
                "type": "COUNT"
            },
            {
                "id": 65,
                "key": "Bank Charges",
                "value": "42135.0",
                "style": "REGULAR",
                "type": "AMOUNT"
            }
        ]
    }
    ]
];

export default ApiResponseDummy;