import React, { useEffect, useState, useMemo } from "react";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const EditProduct = () => {
  const history = useHistory();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [distributorProductType, setDistributorProductType] = useState([]);
  const [distributorSubProductType, setDistributorSubProductType] = useState([]);
  const [distributorUserData, setDistributorUserData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [totalAddBrandRows, setTotalAddBrandRows] = useState([]);
  const [userBrands, setUserBrands] = useState([]);
  const [productStatus, setProductStatus] = useState(false);
  const [prodCatId,setProdCatId] = useState();
  const [distProdObject, setDistProdObject] = useState();
  const [deletedProgramBrandIds, setDeletedProgramBrandIds] = useState([]);

  const [formData, setFormData] = useState({
    productId: "",
    productName: "",
    productCategory: "",
    subCategory: '',
    brand: "",
    size: "",
    color: "",
    mostPurchasedProduct: "",
    filePath1: "",
    filePath2: "",
    filePath3: "",
    description: "",
    userId: "1",
    isActive: 1,
    productCategoryId: "",
    distrubutorProductObject: [{ id: '', distributorId: '', programId: '', mrp: '', listingPrice: '', isDistActive: 1, distributorProductId: -1}],
    deleteDistributorProductObject: [],
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const statusOptions=[
    { label: "Published", value: 1 },
    { label: "Not Published", value: 0 },
  ]
  
  const statusDistOptions=[
    { label: "Published", value: 1 },
    { label: "Not Published", value: 0 },
  ]

  const numberFields = [
  ];

  const parseToNumber = (formData) => {
    const parsedData = { ...formData };
    numberFields.forEach((field) => {
      if (parsedData[field] !== "") {
        parsedData[field] = parseFloat(parsedData[field]);
      }
    });
    return parsedData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== null)
    );
    const updatedDistrubutorProductObject = formData.distrubutorProductObject.map((brand) => {
      return {
        distributorId: brand.distributorId,
        programId: brand.programId,
        mrp: brand.mrp,
        listingPrice: brand.listingPrice,
        isDistActive: brand.isActive,
        distributorProductId: brand.distributorProductId == -1 ? brand.distributorProductId : brand.id,
      };
    });

    const updatedFilePaths = {};
    ["filePath1", "filePath2", "filePath3"].forEach((key) => {
      if (formData[key] && formData[key].startsWith("data:image/")) {
        updatedFilePaths[key] = formData[key];
      }
    });

    const updatedFormData = {
      ...filteredData,
      ...updatedFilePaths,
      distrubutorProductObject: updatedDistrubutorProductObject,
    };


    console.log("ON Update-----", updatedFormData);
    setIsLoadingOn(true);
    const res = await postData(`electrician/updateProductCatalog`, updatedFormData);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      if (res.data.errorObject && res.data.errorObject.length > 0) {
        const errorMessages = res.data.errorObject.map((error) => {
          if (error.type === "MappingError") {
            if (error.distributorId && error.productName) {
              const distributor = distributorUserData.find(
                (d) => d.value === parseInt(error.distributorId)
              );
              const distributorName = distributor ? distributor.label : "Unknown Distributor";
              return `Mapping already exists for Distributor: ${distributorName} (ID: ${error.distributorId}) and Product: ${error.productName} (ID: ${error.productId})`;
            } else if (error.productName && error.productCategory) {
              return `Mapping already exists for Product: ${error.productName} in Category: ${error.productCategory}`;
            }
          }

          return error.message || "An error occurred.";
        });

        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessages,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data updated successfully!",
        });
        history.push("/ProductList");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

    const handleUpdateChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };

  const loadFormDataForEditing = async (id) => {
    try {
        setIsLoadingOn(true);
        const programDataResponse = await getData(`electrician/getDistributorsByProduct/1?productId=${id}`);

        if (programDataResponse.statusCode === 200) {
            const prodData = programDataResponse.data.productResult[0];
            const distProdData = programDataResponse.data.distributorproductResult;
            setFormData({
                ...prodData,
                distrubutorProductObject: distProdData,
            });
            setDistProdObject(distProdData);
            setProdCatId(prodData.productCategoryId);
        } else {
            console.error("Error loading data:", programDataResponse.message);
        }
    } catch (error) {
        console.error("Error loading program data:", error);
    } finally {
      setIsLoadingOn(false);
    }
  };
  
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      loadFormDataForEditing(id);
    }
  }, [id]);

  const getBrands = async () => {
    try {
        setIsLoadingOn(true);
        const tempData = await getData(`users/getBrands/1`);

        if (tempData.statusCode === 200 && tempData.data.records) {
            const tempDistData = tempData.data.records.map((element) => ({
                value: element.id,
                label: element.name,
            }));
            setUserBrands(tempDistData);
        } else {
            console.error("Error fetching User brands.");
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors?.msg || "Something went wrong!!!",
            });
        }
    } catch (error) {
        console.error("Error fetching brands:", error);
    } finally {
        setIsLoadingOn(false);
    }
  };

  useEffect(() => {
    getBrands();
    getProductType();
    if(prodCatId){
      getSubProductType(prodCatId)
    }
  }, [prodCatId]);

  async function getProductType() {
    try {
        setIsLoadingOn(true);
        const tempData = await getData(`users/getProductCategory/nothing`);
        setIsLoadingOn(false);
        if (tempData.statusCode === 200 && tempData.data.records) {
            const tempDistData = tempData.data.records.map(element => ({
                value: element.id,
                label: element.name,
            }));
            setDistributorProductType(tempDistData);
        } else {
            console.error("Error fetching distributor Product Type");
            Swal.fire({
                title:"Error!",
                text:tempData?.data?.errors.msg || "Something went wrong!!!"
              })
        }
    } catch (error) {
        console.error("Error fetching brands:", error);
    } finally {
        setIsLoadingOn(false);
    }
  }
  
  async function getSubProductType(productCategoryId) {
    try {
        setIsLoadingOn(true);
        console.log("productCategoryId 111 ", productCategoryId);
        let tempData = await getData(`users/getProductSubCategory/1?productCategoryId=${productCategoryId}`);
        setIsLoadingOn(false);
        if (tempData.statusCode === 200 && tempData.data.records) {
            const tempDistData = tempData.data.records.map(element => ({
                value: element.id,
                label: element.name,
            }));
            setDistributorSubProductType(tempDistData);
        } else {
            console.error("Error fetching distributor Sub Product Type");
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors.msg || "Something went wrong!!!"
            });
        }
    } catch (error) {
        console.error("Error fetching brands:", error);
    } finally {
        setIsLoadingOn(false);
    }
  }

  const handleAddProductChange = (selectedOption) => {
    if (selectedOption) {
        const selectedProductCategoryId = selectedOption.value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            productCategory: selectedOption.label,
            subCategory: "", 
        }));

        setDistributorSubProductType([]);
        getSubProductType(selectedProductCategoryId);
    } else {
        setDistributorSubProductType([]);
        setFormData((prevFormData) => ({
            ...prevFormData,
            productCategory: "",
            subCategory: "",
        }));
    }
  };

  const handleAddSubProductChange = (selectedOption) => {
    if (selectedOption) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            subCategory: selectedOption.label,
        }));
    }
  };

  const handleAddBrandChange = (selectedOption) => {
    if (selectedOption) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            brand: selectedOption.label,
        }));
    }
  };

  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            isActive: selectedOption.value,
        }));
    }
  };

  async function DistributorGetData() {
    setIsLoadingOn(true);
    try {
        const tempData = await getData("users/distributorUser/1", {});
        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            const tempDistData = tempData.data.records.map((element) => ({
                value: element.id,
                label: element.name
            }));
            setDistributorUserData(tempDistData);
        } else {
            Swal.fire({
                title: "Error!",
                text: tempData?.data?.errors?.msg || "Something went wrong!"
            });
        }
    } catch (error) {
        setIsLoadingOn(false);
        Swal.fire({
            title: "Error!",
            text: "Failed to fetch distributor data."
        });
    }
  }

  async function getProgramData() {
      setIsLoadingOn(true);
      try {
          const tempData = await getData("users/getMasterProgram/1");
          setIsLoadingOn(false);

          if (tempData.statusCode === 200) {
              const tempProgramData = tempData.data.map((element) => ({
                  value: element.id,
                  label: element.programName
              }));
              setProgramData(tempProgramData);
          } else {
              Swal.fire({
                  title: "Error!",
                  text: tempData?.data?.errors?.msg || "Something went wrong!"
              });
          }
      } catch (error) {
          setIsLoadingOn(false);
          Swal.fire({
              title: "Error!",
              text: "Failed to fetch program data."
          });
      }
  }

  useEffect(() => {
      DistributorGetData();
      getProgramData();
  }, []);

  const handleFileInputChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleDeleteImage = (key) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: "",
    }));
  };

  // const handleAddDistributorChange = (rowId, selectedOption) => {
  //   setTotalAddBrandRows((prevRows) =>
  //       prevRows.map((row) =>
  //           row.id === rowId ? { ...row, distributorId: selectedOption.value } : row
  //       )
  //   );

  //   setFormData((prevState) => ({
  //       ...prevState,
  //       distrubutorProductObject: prevState.distrubutorProductObject.map((row) =>
  //           row.id === rowId ? { ...row, distributorId: selectedOption.value } : row
  //       )
  //   }));
  // };

  // const handleAddProgramChange = (rowId, selectedOption) => {
  //   setTotalAddBrandRows((prevRows) =>
  //       prevRows.map((row) =>
  //           row.id === rowId ? { ...row, programId: selectedOption.value } : row
  //       )
  //   );

  //   setFormData((prevState) => ({
  //       ...prevState,
  //       distrubutorProductObject: prevState.distrubutorProductObject.map((row) =>
  //           row.id === rowId ? { ...row, programId: selectedOption.value } : row
  //       )
  //   }));
  // };

  const handleDistributorProductStatusChange = (rowId, selectedOption) => {
    if (totalAddBrandRows.some(row => row.id === rowId)) {
        setTotalAddBrandRows(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, selectedOption } : row
            )
        );
    } else {
        const newRow = { id: totalAddBrandRows.length + 1, selectedOption };
        setTotalAddBrandRows(prevRows => [...prevRows, newRow]);
    }
    setFormData(prevState => ({
        ...prevState,
        distrubutorProductObject: prevState.distrubutorProductObject.map((row, index) =>
          index + 1 === rowId
            ? {
                ...row,
                isDistActive: selectedOption.value,
              }
            : row
        )
    }));
  };

  const handleUpdateRowChange = (event, rowIndex, arrayName) => {
    const { name, value } = event.target;
    const numericValue = value.match(/^-?\d*\.?\d*$/);
    setFormData(prevState => {
        const updatedArray = [...prevState[arrayName]];
        updatedArray[rowIndex] = {
            ...updatedArray[rowIndex],
            [name]: value
        };
        return {
            ...prevState,
            [arrayName]: updatedArray
        };
    });
  };

  const handleDeleteBrandRow = (brand) => {
    const rowId = brand.id; // Extract the row ID
    setFormData((prevState) => ({
      ...prevState,
      deleteDistributorProductObject: [
        ...(prevState.deleteDistributorProductObject || []),
        rowId,
      ],
      distrubutorProductObject: prevState.distrubutorProductObject.filter(
        (row) => row.id !== rowId
      ),
    }));
  
    setTotalAddBrandRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
  };  
  
  const addBrandAddRow = () => {
    const newBrandRow = { id: totalAddBrandRows.length + 1 };
    setTotalAddBrandRows([...totalAddBrandRows, newBrandRow]);
    setFormData(prevState => ({
        ...prevState,
        distrubutorProductObject: [
            ...prevState.distrubutorProductObject,
            { distributorId: '', programId: '', mrp: '', listingPrice: '', isDistActive: 1 }
        ]
    }));
  };

  console.log("formData ===", formData);  
return (
    <>
    {isLoadingOn && <Loader />}
    <div className="container">
        <h3 className="programHead">{isEditMode ? "Update Data" : "Add Product"}</h3>
        <form onSubmit={handleSubmit}>
        <div className="row">
            <h4 className="programDetailsDekho">Product Details</h4>
            <hr />
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="productName" className="form-label">
                    Product Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Product Name"
                    className="form-control"
                    id="productName"
                    name="productName"
                    value={formData.productName}
                    onChange={handleUpdateChange}
                    required
                  />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="creditLimit" className="form-label">
                      Brand<span>*</span>
                  </label>
                  <Select
                      placeholder="Select"
                      name="brand"
                      options={userBrands}
                      value={userBrands.find((brand) => brand.label === formData.brand) || null}
                      onChange={handleAddBrandChange}
                      required
                  />
                  {errors.brand && <div className="text-danger">{errors.brand}</div>}
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="creditLimit" className="form-label">
                      Product Category<span>*</span>
                  </label>
                  <Select
                      placeholder="Select"
                      name="productCategory"
                      options={distributorProductType}
                      value={distributorProductType.find((productCategory) => productCategory.label === formData.productCategory) || null}
                      onChange={handleAddProductChange}
                      required
                  />
                  {errors.productCategory && <div className="text-danger">{errors.productCategory}</div>}
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                  <label htmlFor="creditLimit" className="form-label">
                      Product Sub Category
                  </label>
                  <Select
                      placeholder="Select"
                      name="subCategory"
                      options={distributorSubProductType}
                      isDisabled={!distributorSubProductType || distributorSubProductType.length === 0}
                      value={distributorSubProductType.find(option => option.label === formData.subCategory) || null}
                      onChange={handleAddSubProductChange}
                  />
                  {errors.subCategory && <div className="text-danger">{errors.subCategory}</div>}
              </div>
            </div>
        </div>

        <div className="row">
          <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="size" className="form-label">
                  Size<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Size"
                  className="form-control"
                  id="size"
                  name="size"
                  value={formData.size}
                  onChange={handleUpdateChange}
                  required
                />
            </div>
          </div>

          <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="color" className="form-label">
                  Color<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Color"
                  className="form-control"
                  id="color"
                  name="color"
                  value={formData.color}
                  onChange={handleUpdateChange}
                  required
                />
            </div>
          </div>

          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
                <label htmlFor="description" className="form-label">
                Description<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter description"
                  className="form-control"
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleUpdateChange}
                  required
                />
            </div>
          </div>
        </div>

        <div className="row">
          {["filePath1", "filePath2", "filePath3"].map((key, index) => (
            <div className="col-md-3 changeProductPro" key={key}>
              <div className="mb-3">
                <label htmlFor={key} className="form-label">
                  Image {index + 1}
                </label>
                {formData[key] ? (
                  <div className="image-preview-wrapper" style={{ position: "relative" }}>
                    <img
                      src={formData[key]}
                      alt={`Preview ${index + 1}`}
                      className="img-thumbnail mb-2"
                      style={{ maxHeight: "150px", cursor: "pointer" }}
                      onClick={() => window.open(formData[key], "_blank")}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm rounded-circle"
                      style={{ position: "absolute", top: "10px", right: "10px" }}
                      onClick={() => handleDeleteImage(key)}
                    >
                      &times;
                    </button>
                  </div>
                ) : (
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    id={key}
                    name={key}
                    onChange={(event) => handleFileInputChange(event, key)}
                    aria-describedby="textHelp"
                  />
                )}
              </div>
            </div>
          ))}

          <div className="col-md-3 changeProductPro">
            <div className="mb-3">
              <label htmlFor="isActive" className="form-label">
                Product status<span>*</span>
              </label>
              <Select
                placeholder="Select"
                defaultValue={{ label: "Published", value: 1 }}
                name="isActive"
                options={statusOptions}
                value={
                  statusOptions.find(
                    (status) => status.value === formData.isActive
                  ) || { label: "Published", value: 1 }
                }
                onChange={handleStatusChange}
              />
              {errors.isActive && <div className="text-danger">{errors.isActive}</div>}
            </div>
          </div>
        </div>

        {/* ------------------------------------- Add Brand ------------------------------------ */}
        <>
            <h4 className='programDetailsDekho'>Add Distributors</h4>
            <hr /> 
          {formData.distrubutorProductObject.map((brand, index) => (
            <div key={brand.id} className="row">
              <div className="col-md-2 changeProductPro">
                <div className="mb-3">
                  <label htmlFor={`distributorId-${brand.id}`} className="form-label">
                    Distributors
                  </label>
                  <Select
                    placeholder="Select"
                    name={`distributorId-${brand.id}`}
                    options={distributorUserData}
                    value={
                      distributorUserData.find(
                        (option) => option.value === brand.distributorId
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        distrubutorProductObject: prevState.distrubutorProductObject.map((row) =>
                          row.id === brand.id
                            ? { ...row, distributorId: selectedOption.value }
                            : row
                        ),
                      }))
                    }
                  />
                  {errors.distributorId && (
                    <div className="text-danger">{errors.distributorId}</div>
                  )}
                </div>
              </div>

              {/* Program Select */}
              <div className="col-md-2 changeProductPro">
                <div className="mb-3">
                  <label htmlFor={`programId-${brand.id}`} className="form-label">
                    Program
                  </label>
                  <Select
                    placeholder="Select"
                    name={`programId-${brand.id}`}
                    options={programData}
                    value={
                      programData.find((option) => option.value === brand.programId) ||
                      null
                    }
                    onChange={(selectedOption) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        distrubutorProductObject: prevState.distrubutorProductObject.map((row) =>
                          row.id === brand.id
                            ? { ...row, programId: selectedOption.value }
                            : row
                        ),
                      }))
                    }
                  />
                  {errors.programId && (
                    <div className="text-danger">{errors.programId}</div>
                  )}
                </div>
              </div>

              <div className="col-md-2 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="mrp" className="form-label">MRP</label>
                  <input
                    type="number"
                    step="any"
                    placeholder="Enter MRP"
                    className="form-control"
                    id="mrp"
                    name="mrp"
                    value={brand.mrp || ''}
                    onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                    aria-describedby="textHelp"
                  />
                  {errors.mrp && (
                    <div className="text-danger">{errors.mrp}</div>
                  )}
                </div>
              </div>

              <div className="col-md-2 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="listingPrice" className="form-label">Listing Price</label>
                  <input
                    type="number"
                    step="any"
                    placeholder="Enter Listing Price"
                    className="form-control"
                    id="listingPrice"
                    name="listingPrice"
                    value={brand.listingPrice || ''}
                    onChange={(event) => handleUpdateRowChange(event, index, "distrubutorProductObject")}
                    aria-describedby="textHelp"
                  />
                  {errors.listingPrice && (
                    <div className="text-danger">{errors.listingPrice}</div>
                  )}
                </div>
              </div>

              {/* Distributor Product Status */}
              <div className="col-md-2 changeProductPro">
                <div className="mb-3">
                  <label htmlFor={`isDistActive-${brand.id}`} className="form-label">
                    Distributor Product Status
                  </label>
                  <Select
                    placeholder="Select"
                    name={`isDistActive-${brand.id}`}
                    options={statusDistOptions}
                    value={
                      statusDistOptions.find((option) => option.value === brand.isActive) ||
                      null
                    }
                    onChange={(selectedOption) =>
                      handleDistributorProductStatusChange(
                        brand.id,
                        selectedOption,
                        'distrubutorProductObject'
                      )
                    }
                  />
                  {errors.isDistActive && (
                    <div className="text-danger">{errors.isDistActive}</div>
                  )}
                </div>
              </div>

              {/* Add/Delete Buttons */}
              <div className="col-md-2 changeProductPro">
                {index === 0 ? (
                  <div className="col-md-2">
                    <div
                      className="proPlusIconGreen"
                      onClick={() => {
                        const newRowId = totalAddBrandRows.length + 1; // Generate a new ID
                        const newBrandRow = {
                          id: newRowId,
                          distributorId: "",
                          programId: "",
                          mrp: "",
                          listingPrice: "",
                          isActive: 1,
                          distributorProductId: -1, // Use -1 for unsaved rows
                        };

                        // Add the new row to both state variables
                        setTotalAddBrandRows((prevRows) => [...prevRows, newBrandRow]);
                        setFormData((prevState) => ({
                          ...prevState,
                          distrubutorProductObject: [
                            ...prevState.distrubutorProductObject,
                            newBrandRow,
                          ],
                        }));
                      }}
                    >
                      <FaPlusCircle />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2">
                    <div
                      className="deletedIcon"
                      onClick={() => handleDeleteBrandRow(brand)}
                    >
                      <MdDelete />
                    </div>
                  </div>
                )}
              </div>
          </div>
          ))}
        </>

        {/* ------------------------------------------------------------------------------------------- */}

        <div className="text-center">
            <button type="submit" className="btn btn-success">
            {isEditMode ? "Update" : "Submit"}
            </button>
        </div>
        </form>
    </div>
    </>
);

};

export default EditProduct;