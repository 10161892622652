import React, { useEffect, useState, useRef, useMemo } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import "../../css/makeTable.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  formatDate,
  formatDateTimeSecond,
  formatDateTimeString,
  formatNumberWithCommasAndDecimals,
} from "../../component/common.js";
import { FaPlusCircle } from "react-icons/fa";
import Select from "react-select";

const MerchantRepaymentDetails = () => {
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [merchantRepaymentInfo, setMerchantRepaymentInfo] = React.useState([]);

  console.log("merchantRepaymentInfo++++", merchantRepaymentInfo);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");

  const data = useMemo(
    () => [
      {
        distId: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
        data1: "9988776786",
        action: "9988776786",
      },
    ],
    []
  );

  const columns = useMemo(() => [
    {
      Header: "Sr. No.",
      accessor: (row, i) => i + 1,
      Cell: ({ cell }) => (
        <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
          {cell.row.index + 1}.
        </span>
      ),
    },
    {
      Header: "Loan Id",
      accessor: "loanId",
      filter: "fuzzyText",
      disableSortBy: true,
      disableFilters: false,
    },
    {
      Header: "Order Id",
      accessor: "orderId",
      Cell: ({ cell }) => {
        const orderType = cell.row.original;
        const handleClick = (user) => {
          if (orderType.type === "MERCHANT") {
            window.open(
              `FundInvoiceDetails?invoiceId=${orderType.orderId}`,
              "_blank"
            );
          } else if (orderType.type === "ORDER") {
            window.open(
              `OrderDetailsPage?userId=${orderType.userId}&orderId=${orderType.orderId}`,
              "_blank"
            );
          }
        };
        return (
          <>
            <button
              className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
              title="View details"
              onClick={handleClick}
            >
              <span style={{ fontWeight: "600" }}>{orderType.orderId}</span>
            </button>
          </>
        );
      },
    },
    {
      Header: "Order Date",
      accessor: "orderDate",
    },
    {
      Header: "Loan Type",
      accessor: "type",
    },
    {
      Header: "Shop Owner Name",
      accessor: "userName",
    },
    {
      Header: "Shop Name",
      accessor: "shopName",
    },
    {
      Header: "Disbursed Amt",
      accessor: "totalDisbursedAmount",
      Cell: ({ value }) => {
        if (value) {
          // Remove any commas from the value and convert it to a number
          const numericValue = parseFloat(value.replace(/,/g, ""));
          if (!isNaN(numericValue)) {
            return formatNumberWithCommasAndDecimals(numericValue);
          } else {
            return value || "-";
          }
        } else {
          return "-";
        }
      },
    },

    {
      Header: "Repayment Amt",
      accessor: "amountWithExtraCharges",
      Cell: ({ value }) => {
        if (typeof value === "number") {
          return formatNumberWithCommasAndDecimals(value);
        } else {
          return value;
        }
      },
    },
    {
      Header: "EMI Date",
      accessor: "emiDate",
      Cell: ({ cell }) => {
        return (
          <>
            <span>
              {cell.row.original.emiDate &&
                formatDateTimeString(cell.row.original.emiDate)}
            </span>
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "statusName",
      Cell: ({ cell }) => {
        const status = cell.row.original.statusName;
        return (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {status && (
                <span
                  class={`badge badge-pill ${
                    status == "Paid"
                      ? "badge-success"
                      : status == "Paid By Credit"
                      ? "badge-warning"
                      : status == "Pending"
                      ? "badge-danger"
                      : "badge-primary"
                  }`}
                >
                  {status}
                </span>
              )}
            </div>
          </>
        );
      },
    },

    // {
    //     Header: 'Status',
    //     accessor: 'repaymentStatus',
    // },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ cell }) => {
        const userId = cell.row.original.userId;
        const orderId = cell.row.original.orderId;
        const loanType = cell.row.original.type;
        return (
          <>
            <div className="loanIdDownloadBtnDiv text-center">
              <button
                className={`btn btn-outline-success`}
                onClick={() => {
                  const userRoleCheck = localStorage.getItem("USER_ROLE");
                  if (
                    userRoleCheck === "Admin" ||
                    userRoleCheck === "Operation"
                  ) {
                    // statusModalOpen(cell.row.original);
                    const newTab = window.open("", "_blank");
                    if (newTab) {
                      newTab.location =
                        "/RepaymentDetails?userId=" +
                        userId +
                        "&orderId=" +
                        orderId +
                        "&loanType=" +
                        loanType;
                    }
                  }
                }}
              >
                <span style={{ fontWeight: "600" }}>View</span>
              </button>
            </div>
          </>
        );
      },
    },
  ]);

  // ------------- Merchant Bank Data --------------------------

  async function merchantGetRepaymentData(userId) {
    setIsLoadingOn(true);
    const tempData = await getData(
      `order/getAllInvoiceDueDate/1?userId=${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantRepaymentInfo(tempData?.data?.records);
    }
  }

  useEffect(() => {
    if (userId) {
      merchantGetRepaymentData(userId);
    }
  }, [userId]);

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              Invoice
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
          >
            <div className="accordion-body">
              {merchantRepaymentInfo && merchantRepaymentInfo.length == 0 ? (
                <p style={{ fontSize: 22, marginTop: 20, fontWeight: "600" }}>
                  No Data Found
                </p>
              ) : (
                <Table columns={columns} data={merchantRepaymentInfo} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantRepaymentDetails;

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 1000 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== "number";
